<template>
  <v-dialog
    v-model="showHistory"
    max-width="768"
    scrollable
    :fullscreen="dialogHistoryFullscreen"
  >
    <v-card outlined>
      <v-card-title>
        {{$t('modules.dialog.texts.history.modal_title_restore')}}
        <v-spacer />

        <v-btn
          @click="
            dialogHistoryFullscreen = !dialogHistoryFullscreen;
            dialogHistoryFullscreen ? getInfinityScrollEntityList($refs.InfiniteHistoryLoading.stateChanger) : null;
          "
          icon
          small
        >
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>

        <v-btn
          @click="showHistory = false"
          icon
          small
          class="ml-2"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-simple-table
          class="table-infinity-scroll"
          fixed-header
          :height="dialogHistoryFullscreen ? 'calc(100vh - 90px)' : '300'"
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left" scope="col">
                {{$t('modules.dialog.texts.history.created_date')}}
              </th>
              <th class="text-left" scope="col">
                {{ $t('modules.dialog.texts.history.who_edit') }}
              </th>
              <th class="text-right" scope="col"></th>
            </tr>
            </thead>
            <tbody v-if="!loading">
              <tr
                v-for="(item, index) in entity_history_data"
                :key="item._id"
                :style="{
                  backgroundColor: isCurrentScenario(item, index) ? '#5784eb' : null,
                  color: isCurrentScenario(item, index) ? '#fff' : null,
                }"
              >
                <td
                  class="text-caption"
                >{{new Date(item.createdAt).toLocaleString()}}</td>
                <td>{{item.constructor_user_email}}</td>
                <td style="text-align: right;">
                  <v-btn
                    v-if="false && !isActualScenario(item)"
                    class="mr-2"
                    icon
                    color="success"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>

                  <template
                    v-if="
                      (!$route.params?.scenario_id && isActualScenario(item))
                      || isCurrentScenario(item)
                    "
                  >
                    <v-chip
                      small
                      outlined
                      rounded
                      style="color: inherit"
                    >{{ $t('modules.dialog.texts.history.current') }}</v-chip>
                  </template>

                  <template v-else>
                    <template
                      v-if="!isActualScenario(item)"
                    >
                      <v-btn
                        class="text-none mr-2"
                        elevation="0"
                        x-small
                        @click="activateItem(item, true)"
                      >
                        {{ $t('modules.dialog.texts.history.with_current') }}
                      </v-btn>
                    </template>
                    <v-btn
                      elevation="0"
                      :color="
                        isActualScenario(item)
                        ? 'success'
                        : 'primary'
                      "
                      x-small
                      @click="
                        isActualScenario(item)
                          ? ( showHistory = false, $router.push({
                            name: 'bot_scenario',
                            params: { id: $route.params.id }
                          }), reloadPage())
                          : activateItem(item)
                      "
                      class="text-none"
                    >
                      {{
                        isActualScenario(item)
                          ? $t('modules.dialog.texts.history.activate')
                          : $t('modules.dialog.texts.history.show')
                      }}</v-btn>
                  </template>
                </td>
              </tr>

              <infinite-loading
                spinner="spiral"
                @infinite="getInfinityScrollEntityList"
                :identifier="infiniteId"
                force-use-infinite-wrapper=".table-infinity-scroll .v-data-table__wrapper"
                ref="InfiniteHistoryLoading"
              >
                <span slot="no-results">
                  {{$t('modules.dialog.texts.history.its_first')}}
                </span>
                <span slot="no-more">
                  {{$t('modules.dialog.texts.history.no_more')}}
                </span>
              </infinite-loading>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'ModalScenarioHistory',

  components: {
    InfiniteLoading,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    }
  },

  data: () => ({
    loading: true,
    dialogHistoryFullscreen: false,

    // infinity scroll
    defaultDataInitial: {
      "offset": 0,
      "limit": 20,
    },
    dataInitial: {
      "offset": 0,
      "limit": 20,
    },

    entity_history_data: [],
    infiniteId: +new Date(),
  }),

  computed: {
    ...mapGetters([
      'getScenarioHistory',
    ]),

    showHistory: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
  },

  async mounted() {
    await this.axiosGetBotScenarioHistory(this.dataInitial).then((response) => {
      this.entity_history_data = response;
      this.dataInitial.offset = this.entity_history_data.length
      this.loading = false
    })
  },

  watch: {
    value: function(val) {
      if(!val) this.resetInfinityScrollEntityList();
    }
  },

  methods: {
    ...mapActions([
      'axiosGetBotScenarioHistory',
    ]),
    activateItem(item, keep_current = null) {
      this.showHistory = false

      const routerData = {
        name: 'bot_scenario_history',
        params: {
          scenario_id: item._id,
        },
      }

      if(keep_current) {
        routerData.query = {
          keep_current: keep_current,
        }
      }

      this.$router.push(routerData)

      this.reloadPage()
    },

    reloadPage() {
      location.reload()
    },

    isCurrentScenario(item) {
      return item._id === this.$route.params?.scenario_id
    },
    isActualScenario(item) {
      return item?._id === this.entity_history_data[0]?._id
    },

    async getInfinityScrollEntityList($state) {
      if(this.dataInitial.offset < this.dataInitial.limit) {
        $state.complete();
        return;
      }

      await this.axiosGetBotScenarioHistory(this.dataInitial)
        .then((response) => {
          if (response?.length > 0) {
            this.entity_history_data.push(...response);
            this.dataInitial.offset = this.entity_history_data.length
            $state.loaded();
          } else {
            $state.complete();
          }
        })
    },
    resetInfinityScrollEntityList() {
      this.dataInitial = JSON.parse(JSON.stringify(this.defaultDataInitial));
      this.entity_history_data = [];
      this.infiniteId += 1;

      // this.$refs.InfiniteHistoryLoading?.stateChanger?.reset();
    },
  },
}
</script>
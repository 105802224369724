<template>
  <v-card
    v-if="custom_data_tables.length === 0 && modulesCheck"
    max-width="600"
    class="modules-list-empty mt-5"
  >
    <v-card-title class="justify-center">
      {{$t('modules.op.texts.empty_modules_tables_title')}}
    </v-card-title>

    <v-card-text>
      {{$t('modules.op.texts.empty_modules_tables_text')}}
    </v-card-text>
  </v-card>

  <div
    v-else
    :class="{ 'statistics-showed' : dynamicStatisticsShowed }"
    class="cd__container"
  >
    {{ /* Top navigation for table  */ }}
    <div class="cd__top-nav">
      <div class="cd__right">
        <v-tabs
          background-color="transparent"
          class="cd__tabs mb-2"
          height="35"
          slider-size="2"
          v-model="tabActive"
          @change="loadData(custom_data_tables[tabActive])"
        >

          <v-tooltip 
            top
            v-for="( item, i ) in custom_data_tables"
            :key="i"
          >
            <template
              v-slot:activator="{on}"
            >
              <v-tab
                v-on="on"
                @click="changeTabRoute(item)"
                :title="item.description"
              >
                {{ item.name }}
              </v-tab>
            </template>
            <span>{{item.description}}</span>
          </v-tooltip>

        </v-tabs>
        <v-spacer></v-spacer>

        <div class="cd__actions">

            {{ /** ADD BUTTON **/}}
            <v-btn
              elevation="0"
              color="primary"
              height="30"
              class="mr-2"
              @click="openModal('create_mode')"
            >
              <v-icon left> mdi-plus </v-icon>
              {{ i18n.t('modules.customdata.entity.add') }}
            </v-btn>
            {{ /** end ADD BUTTON **/}}

            {{ /* Search */ }}
            <v-layout
              class="custom-data__search"
            >
              <v-text-field
                class="pt-0 mt-0 mr-2"
                v-model="searchInTable"
                @input="handleSearchInput()"
                @keydown.enter="handleSearchInput('onEnter')"
                prepend-inner-icon="mdi-magnify"
                :label="$t('modules.customdata.entity.search')"
                single-line
                hide-details
                height="30"
                clearable
                @click:clear="applyFilter(true)"
                @focus="isSearchActive = true"
                @blur="disableSearchActive"
              ></v-text-field>
            </v-layout>
            {{ /* end Search */ }}

            {{ /* menu Actions for Table */ }}
            <v-menu
              offset-y
              rounded='sm'
              bottom
              min-width="248"
              nudge-bottom="12"
              nudge-left="14"
              content-class="v-custom-menu__list elevation-0"
              :close-on-content-click=false
              :disabled="isSearchActive"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  height="30"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  class="v-custom-menu__list--btn mr-2"
                >
                  <v-icon class="mr-1" small>mdi-gamepad-circle-down</v-icon>
                  {{ $t("modules.customdata.entity.actions") }}
                </v-btn>
              </template>

              <v-list dense>
                <div class="v-custom-menu__list--wrapper">
                  <v-list-item-group>

                    <v-list-item
                      v-if="canSave"
                      :disabled="selected.length < 1"
                      :color="selected.length < 1 ? 'gray1' : ''"
                      @click="deleteSelectedItem(selected)"
                    >
                      <v-list-item-icon>
                        <v-icon 
                          :color="selected.length < 1 ? 'gray1' : ''"
                        >mdi-delete-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{i18n.t('modules.customdata.entity.del_selected')}}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-group>
                      <template v-slot:activator>
                        <v-list-item-icon>
                          <v-icon>mdi-download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{i18n.t('modules.customdata.entity.export')}}
                        </v-list-item-title>
                      </template>

                      <v-list-item
                        v-for="(item, index) in exportType"
                        :key="index"
                        v-on:click="getExportFile(item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                    </v-list-group>

                    <v-list-item v-if="false">
                      <v-list-item-icon>
                        <v-icon>mdi-upload</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Import
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="canSave">
                      <v-list-item-icon>
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        @click="clearModel"
                      >{{ $t('modules.customdata.clear') }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </div>
              </v-list>
            </v-menu>
            {{ /* end menu Actions for Table */ }}

          {{ /* Show/Hide Filters and Saved Filters List */ }}
          <v-layout
            align-center
            class="v-custom-menu__list--filter-btns mr-2"
            :class="{ 'active' : toggleActiveFilter }"
          >
            <v-btn
              class="filter-btns__btn-show"
              color=""
              elevation="0"
              @click="showFilters = !showFilters"
              text
            >
              <v-icon class="mr-1" small v-if="!showFilters">mdi-filter</v-icon>
              <v-icon class="mr-1" small v-else color="primary">mdi-filter-off</v-icon>
              {{ $t("modules.customdata.entity.filter") }}
            </v-btn>

            <v-divider
              v-if="custom_data_saved_filters.length"
              vertical />

            <v-menu
              v-if="custom_data_saved_filters.length"
              offset-y
              rounded='sm'
              bottom
              min-width="208"
              nudge-bottom="12"
              nudge-left="14"
              :close-on-content-click=false
              content-class="v-custom-menu__list elevation-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="setHeaderColumnsFromState()"
                  class="filter-btns__btn-menu"
                >
                  <!-- <v-icon v-if="attrs['aria-expanded'] === 'true'">mdi-menu-up</v-icon>
                  <v-icon v-else>mdi-menu-down</v-icon> -->
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list dense class="pb-0">
                <v-subheader>{{ $t("modules.customdata.entity.saved_filters") }}</v-subheader>

                <div class="v-custom-menu__list--wrapper with_fixed-height">
                  <vue-scroll>
                    <v-list-item-group
                      v-model="toggleActiveFilter"
                      color="primary"
                    >
                      <v-list-item
                          v-for="(item, i) in custom_data_saved_filters"
                          :key="`item-${i}`"
                          :value="item"
                          v-ripple="false"
                        >
                          <v-list-item-content>
                            <v-list-item-title

                              v-text="item.name"></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon
                            v-if="canSave"
                            @click.stop="onDeleteFilter(item.name)"
                          ><v-icon size="14" color="red">$icondelete</v-icon>
                          </v-list-item-icon>

                        </v-list-item>
                    </v-list-item-group>

                    <v-divider></v-divider>

                    {{/* Show all (clear filters) */}}
                    <v-list-item-group>
                      <v-list-item
                        @click="clearAllFilters"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="$t('modules.customdata.entity.show_all')"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                    {{/* end Show all (clear filters) */}}

                  </vue-scroll>
                </div>

              </v-list>
            </v-menu>

          </v-layout>

          {{ /* end Show/Hide and Filters List */ }}

          {{ /* Show/Hide table headers toggle */ }}
          <v-menu
            offset-y
            rounded='sm'
            bottom
            min-width="208"
            max-height="500"
            nudge-bottom="12"
            nudge-left="14"
            :close-on-content-click=false
            content-class="v-custom-menu__list elevation-0"
            :disabled="isSearchActive"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                height="30"
                color="transparent"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                class="v-custom-menu__list--btn mr-2"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  <!-- $vuetify.icons.iconeyeno -->
                  mdi-eye-off
                </v-icon>
                <!-- <v-icon v-if="attrs['aria-expanded'] === 'true'">mdi-menu-up</v-icon>
                <v-icon v-else>mdi-menu-down</v-icon> -->
                {{ $t("modules.customdata.entity.hide") }}
              </v-btn>
            </template>

            <v-list dense>
              <v-subheader>{{ $t("modules.customdata.entity.title_column_chooser") }}</v-subheader>
              <div class="v-custom-menu__list--wrapper with_fixed-height">
                <vue-scroll>
                  <v-list-item-group multiple>
                    <draggable
                      @end="customDataChangeOrderColumn($event)"
                      handle=".drag_btn"
                      class="list-group"
                      :disabled="!enabled"
                      ghost-class="draggable-ghost"
                    >
                      <template
                        v-for="(item, i) in headerColumns">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item"
                          v-ripple="false"
                          :class="'dataitemname__' + item.name"
                        >

                          <span class="drag_btn">
                            <v-icon>mdi-drag</v-icon>
                          </span>
                          <v-list-item-action>
                            <v-checkbox
                              :input-value="item.active"
                              color="primary"
                              dense
                              @change="
                                customDataToggleColumnActive(
                                  {
                                    name: item.name,
                                    value: $event,
                                  }
                                )"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                          </v-list-item-content>

                        </v-list-item>
                      </template>
                    </draggable>
                  </v-list-item-group>
                </vue-scroll>
              </div>

              <v-layout
                v-if="canSave"
                align-center
                class="col-chooser-btn-wrap px-6 pt-3 pb-2"
              >
                <v-btn
                  small
                  color="primary"
                  elevation="0"
                  class="mr-6"
                  @click="
                    custom_data_toggle_active_filter
                    ? onSaveFilters({name: custom_data_toggle_active_filter.name, with_columns: true})
                    : customDataAxiosSaveColumnsData({name: multiple_entity, reset: false})
                  "
                  v-text="
                    custom_data_toggle_active_filter
                    ? $t('modules.customdata.columns_chooser.save_columns_for_filter')
                    : $t('modules.customdata.columns_chooser.save_columns')
                  "
                ></v-btn>

                <v-btn
                  small
                  color="primary"
                  outlined
                  elevation="0"
                  @click="
                    custom_data_toggle_active_filter
                    ? resetColumnsChooserForFilter()
                    : resetColumnsChooser()
                  "
                >{{ $t("modules.customdata.columns_chooser.clear_columns") }}</v-btn>
              </v-layout>

            </v-list>

          </v-menu>
          {{ /* end Show/Hide table headers toggle */ }}

          {{ /* Show/Hide dynamic statistics */ }}
          <v-btn
            height="30"
            color="transparent"
            elevation="0"
            @click="dynamicStatisticsShowed = !dynamicStatisticsShowed; scrollToComponent()"
            v-if="dynamicStatisticsAvailable"
            >
              <v-icon
                :color="dynamicStatisticsShowed ? 'primary' : ''"
                small
                class="mr-1"
              >
                mdi-poll
              </v-icon>
            {{ $t("modules.customdata.statistics") }}
          </v-btn>
          {{ /* end Show/Hide dynamic statistics */ }}

        </div>
      </div>
    </div>
    {{ /* end Top navigation for table */ }}

    <div
      class="cd__bottom-nav"
     v-if="load"
    >
      <div
        class="cd__left"
        v-show="showFilters"

      >
        {{ /* Filters Component */ }}
        <DynamicFilters
          :selected_filters="custom_data_selected_filters"
          :custom_toggle_active_filter="custom_data_toggle_active_filter"
          :filtersList="custom_data_filters.filter(obj => obj.hidden_all !== 'true')"
          :custom_linked_tables_values="custom_data_linked_tables_values"
          @apply="applyFilter()"
          :savedFiltersList="custom_data_saved_filters"
          @save="onSaveFilters($event)"
          @delete-filter="onDeleteFilter($event)"
          @reset-columns="resetColumnsChooserForFilter()"

          :action_toggle_active_filter="'customDataSetToggleActiveFilter'"
          :action_selected_filters_empty="'customDataSetSelectedFiltersEmpty'"
          :action_delete_selected_filter_in_array="'customDataDeleteSelectedFilterInArray'"

          :action_set_selected_filter_value="'customDataSetSelectedFilterValue'"
          :action_set_selected_filter_condition="'customDataSetSelectedFilterCondition'"

          :canSave="canSave"
        />
        {{ /* end Filters Component */ }}
      </div>

      <div class="cd__right cd__table">
        {{ /* selected table description */ }}
        <!-- <div class="table-descr">{{model_description}}</div> -->

        <DynamicTable
          :items="items || []"
          :headers="headers"
          :selected.sync="selected"
          :selectedHeaders="custom_data_columns_data"
          :searchInTable="searchInTable"
          :loading="loading"
          :items-length="length"
          :page-count="pageCount"
          :default-per-page="defaultPerPage"
          :items-per-page="itemsPerPage"
          :multiple_entity="multiple_entity"
          @change-default-per-page="changeDefaultPerPage($event)"
          @change-page="changePage($event)"
          @sortOptions="onSort"
          @edit-item="editItem($event)"
          @delete-item="deleteItem($event)"
          @update-list="getItems"
          :default-sort="'id'"
          select_active
          propTotalRow
          ref="DynamicTable"
          class="dynamic-table"
        />

        <DynamicStatistics
            :filters="setFiltersObject()"
            :multiple_entity="multiple_entity"
            ref="DynamicStatistics"
            v-if="dynamicStatisticsShowed"
            :isShowed="dynamicStatisticsShowed"
        />

      </div>
    </div>

    {{/** MODAL WINDOW EDIT/ADD ENTITY **/}}
    <template>
      <div class="text-center">
        <v-navigation-drawer
          v-model="modal"
          class="cd_modal"
          absolute
          temporary
          right

          ref="drawer"
          :width="navigation.width"
        >

          {{/** MODAL CLOSE BTN **/}}
          <v-btn
            class="modal__close-btn"
            icon
            small
            absolute
            top
            left
            @click="modal = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
          {{/** END MODAL CLOSE BTN **/}}

          {{/** MODAL MENU **/}}
          <v-menu
            v-if="!modal_create_mode"
            offset-y
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                icon
                absolute
                small
                top
                right
                v-bind="attrs"
                v-on="on"
                class="modal__menu-btn"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
      
            <v-list>
              <v-list-item
                v-if="canSave"
                link
                @click="deleteItemDrawer"
                style="display:flex; align-items:center;"
              >
                <v-icon size="14" class="mr-2 mb-1">$icondelete</v-icon>
                <v-list-item-title class="text-body-2" v-text="i18n.t('modules.customdata.entity.delete')" />
              </v-list-item>
            </v-list>
          </v-menu>
          {{/** END MODAL MENU **/}}

          <v-form ref="form" v-model="form_valid" class="settings-form">
            <v-card elevation="0">
              <v-card-title class="navigation-drawer__title">
                <template v-if="modal_create_mode">{{i18n.t('modules.customdata.entity.modal_title_create')}}</template>
                <template v-else>{{i18n.t('modules.customdata.entity.modal_title')}}</template>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    {{/** ENTITY FIELDS **/}}
                    <template v-for="(field, key) in entity_fields">

                      {{/* print custom fields */}}
                      <template v-if="field.hasOwnProperty('association') && modal">
                        <v-flex xs12 :key="key" >
                          <v-select
                            outlined
                            dense
                            :value="getItemObjValueForAssociation(key)"
                            @input="setItemObjValueForAssociation(key, $event)"
                            :label="`${returnFilterKeyOrLabel(key, field)}`"
                            item-text="name"
                            item-value="id"
                            :items="custom_data_linked_tables_values[key]"
                            return-object
                            :readonly="!modal_create_mode && !canSave"
                          />
                        </v-flex>
                      </template>

                      <template v-else-if="!field.hidden_all">
                        <v-flex xs12 :key="key" >
                          {{/*STRING FIELDS*/}}
                          <v-text-field
                            outlined
                            dense
                            v-if="['STRING'].includes(field.type)"
                            v-model.trim="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="field.rules || [rules.stringMaxLength]"
                            :readonly="!modal_create_mode && !canSave"
                          ></v-text-field>

                          {{/*TEXTAREA FIELDS*/}}
                          <v-textarea
                            outlined
                            dense
                            v-if="['TEXT'].includes(field.type)"
                            v-model.trim="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="field.rules"
                            auto-grow
                            rows="2"
                            :readonly="!modal_create_mode && !canSave"
                          ></v-textarea>

                          {{/*INTEGER FIELDS*/}}
                          <v-text-field
                            outlined
                            dense
                            v-if="['INTEGER'].includes(field.type)"
                            v-model.trim="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="[rules.onlyDigits]"
                            :readonly="!modal_create_mode && !canSave"
                          ></v-text-field>

                          {{/*FLOAT FIELDS*/}}
                          <v-text-field
                            outlined
                            dense
                            v-if="['FLOAT'].includes(field.type)"
                            v-model.trim="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            :rules="[rules.floats]"
                            :readonly="!modal_create_mode && !canSave"
                          ></v-text-field>

                          {{/*BOOL FIELDS*/}}
                          <v-checkbox
                            v-if="field.type === 'BOOLEAN'"
                            v-model="item_obj[key]"
                            :label="returnFilterKeyOrLabel(key, field)"
                            hide-details
                            :rules="field.rules"
                            class="mt-0 mb-4"
                            :readonly="!modal_create_mode && !canSave"
                          ></v-checkbox>

                          {{/*DATE FIELDS*/}}
                          <v-menu
                            v-if="['DATE'].includes(field.type)"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="!modal_create_mode && !canSave"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                dense
                                :value="formatDate(item_obj[key], false, true)"
                                :label="returnFilterKeyOrLabel(key, field)"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item_obj[key]"
                              no-title
                              :locale="i18n.locale"
                              scrollable
                            >
                            </v-date-picker>
                          </v-menu>

                          {{/*FILE FIELDS*/}}
                          <div class="custom-data__file" v-if="field.file_upload && item_obj.id">

                            <div class="custom-data__file--label">{{ returnFilterKeyOrLabel(key, field) }}</div>

                            <div class="d-flex align-center">
                              <div
                                class="d-flex align-center custom-data__file--item"
                                @click="openFileDialog($event)"
                              >

                                <template v-if="item_obj[key] && item_obj[key].files">
                                  <a
                                    :href="$sanitizedHrefHttps( file.url )"
                                    target="_blank" rel="noopener"
                                    v-for="(file, fileIndex) in item_obj[key].files"
                                    :key="fileIndex"
                                    class="file-item-link"
                                  >
                                    <v-img
                                      v-if="isImage(file.name)"
                                      height="40"
                                      width="40"
                                      :src="file.url"
                                    ></v-img>

                                    <v-icon v-else size="50" color="black3">mdi-file</v-icon>

                                    <v-icon
                                      size="14"
                                      color="primary"
                                      @click.prevent.stop="deleteFile(file.name, item_obj.id, key)"
                                      class="file-item-del"
                                    >mdi-close-circle</v-icon>

                                  </a>
                                </template>
                              </div>

                              <v-btn
                                elevation="0"
                                height="36"
                                width="36"
                                min-width="auto"
                                text
                                class="custom-table__file--btn px-0 order-1 ml-2"
                                @click="openFileDialog($event)"
                              >
                                <v-icon size="20" color="primary">mdi-plus-circle-outline</v-icon>
                              </v-btn>

                              <v-file-input
                                multiple
                                v-model="uploadFiles"
                                accept="image/png, image/jpeg, image/jpg, .pdf, .doc, .docx, .xls, .xlsx"
                                :label="$t('modules.customdata.upload')"
                                v-on:change="item_obj.id ? uploadFile(item_obj.id, $event, key) : false"
                                class="fileInput"
                                style="display: none;"
                              ></v-file-input>

                            </div>
                          </div>

                        </v-flex>
                      </template>
                    </template>

                  </v-layout>
                </v-container>
              </v-card-text>

              <v-footer>
                <v-layout justify-start>
                  <v-btn
                    class="mr-4"
                    color="primary"
                    elevation="0"
                    @click="save"
                    small
                    :disabled="!modal_create_mode && !canSave"
                  >
                    <template v-if="modal_create_mode">{{i18n.t('common.create')}}</template>
                    <template v-else>{{i18n.t('common.save')}}</template>

                  </v-btn>
                  <!-- <v-btn
                    class="mr-4"
                    color="primary"
                    elevation="0"
                    outlined
                    @click="modal = false"
                    small
                  >{{ i18n.t('common.close') }}</v-btn> -->

                </v-layout>
              </v-footer>
            </v-card>
          </v-form>
        </v-navigation-drawer>
      </div>
    </template>

  </div>
</template>

<script>
import DynamicTable from "./../../components/DynamicTable.vue";
import DynamicStatistics from "./../../components/DynamicStatistics.vue";
import DynamicFilters from "./../../components/DynamicFilters.vue";
import {mapActions, mapGetters} from "vuex";
import draggable from 'vuedraggable';

import customDataMixin from './customDataMixin';
import Vue from "vue";
import axios from "axios";
import i18n from '@/lang/i18n';

export default {
  name: "CustomDataTab",

  mixins: [customDataMixin],

  components: {
    DynamicTable,
    DynamicFilters,
    DynamicStatistics,
    draggable,
  },

  data: () => ({
    i18n,
    enabled: true,

    //table data
    headers: [],
    selectedHeaders: [],
    headerColumns: [],

    // counters
    defaultPerPage: 20,
    itemsPerPage: [5, 10, 20, 50, 100],
    pageCount: 0,
    loading: false,

    //search table
    searchInTable: "",

    //Filters data
    filtersList: {},

    //selected table rows
    selected: [],

    dafaultDataInitial: {
      "offset": 0,
      "limit": 20,
      "order": {
        "sortBy": "id",
        "sortDesc": true,
      }
    },

    dataInitial: {
      "offset": 0,
      "limit": 20,
      "order": {
        "sortBy": "id",
        "sortDesc": true,
      }
    },

    //new
    load: false,
    modulesCheck: false,
    multiple_entity: null,
    model_description:null,
    entity_fields: [],
    entity: null,

    length: null,

    item_obj: {
      id: null,
    },
    item_empty_obj: {},
    multipleSelectItems: [],

    entity_data: null,

    modal: false,
    form_valid: true,
    modal_create_mode: false,

    exportType: ['csv', 'xls', 'pdf', 'xml', 'html'],

    showFilters: false,
    filters: [],

    linkedTablesValues: {},
    tabActive: null,

    //Panel resize
    navigation: {
        width: localStorage.getItem("drawerWidth") || '50%',
        borderSize: 8
    },
    //Panel resize

    dynamicStatisticsShowed: false,
    dynamicStatisticsAvailable: false,

    isSearchActive: false,
    uploadFiles: [],
  }),

  mounted: async function () {
    await this.CustomDataAxiosGetTables(this.$route?.params?.id);

    if(this.custom_data_tables?.length) {
      if(this.$route?.params?.model_name) {
        const table = this.custom_data_tables.filter( (t) => t.name === this.$route.params.model_name )

        this.multiple_entity = table[0].name
        this.model_description = table[0].description
        this.entity_fields = table[0].columns_json

        //set Active Tab
        this.tabActive = this.custom_data_tables.indexOf(table[0])

        this.modulesCheck = true

      } else {
        this.multiple_entity = this.custom_data_tables[0]?.name
        this.model_description = this.custom_data_tables[0].description
        this.entity_fields = this.custom_data_tables[0].columns_json
        this.changeTabRoute(this.custom_data_tables[0])

        this.modulesCheck = true
      }
    }

    //Panel resize
    this.setBorderWidth();
    this.setEvents();
    //Panel resize

    this.$root.$on('CustomDataTab', async (request) => {
      await this.saveCustomEntityList(request, request.id)
      await this.getItems();
    })
  },

  beforeDestroy: function () {
    this.setEvents(true); // Pass true to remove EventListeners
  },

  computed: {
    ...mapGetters([
      'user',
      'rules',
      'custom_data_tables',
      'custom_data_filters',
      'custom_data_linked_tables_values',
      'custom_data_selected_filters',
      'custom_data_saved_filters',
      'custom_data_columns_data',
      'custom_data_columns_data_activated',
      'custom_data_saved_columns_data',
      'custom_data_default_columns_data',

      'custom_data_toggle_active_filter',

      'engine_settings',
      'bot_settings'
    ]),

    items: function () {
      let items = [];

      if (this.entity_data) {
        for (let item of this.entity_data) {
          if (item.id) {
            items.push(item);
          }
        }
      }
      return items;
    },

    //saved Filters ist toggle
    toggleActiveFilter: {
      get: function() {
        return this.custom_data_toggle_active_filter
      },
      set: async function(value) {
        if (!value) value = null
        await this.customDataSetToggleActiveFilter(value)
        this.resetPagination()
        this.changeDefaultPerPage(this.defaultPerPage)

        //for updating columns list in this component
        this.setHeaderColumnsFromState()
        // this.headerColumns = this._.cloneDeep(this.custom_data_columns_data)
      },
    },

    canSave() {
      return this.user && this.user.role.permissions.custom_data.sections.custom_data.canSave;
    },
  },

  methods: {
    ...mapActions([
      'CustomDataAxiosGetTables',
      'CustomDataAxiosClearModel',
      'customDataToggleColumnActive',
      'customDataChangeOrderColumn',
      'customDataAxiosSaveColumnsData',
      'customDataResetColumnsDataToDefault',
      'customDataResetColumnsDataForFilterToSaved',
      'customDataSetToggleActiveFilter',
      'customDataAxiosSaveFilters',
    ]),

    onSort(data){
      if (data.sortBy.length > 0){
        this.dataInitial.order.sortBy = data.sortBy[0];
        this.dataInitial.order.sortDesc = data.sortDesc[0];
        this.getItems();
      }
    },

    clearAllFilters() {
      this.$root.$emit('clear-all-filters');
    },

    //Panel resize
    setBorderWidth() {
        let i = this.$refs.drawer.$el.querySelector(
            ".v-navigation-drawer__border"
        );
        i.style.width = this.navigation.borderSize + "px";
        i.style.cursor = "ew-resize";
    },

    setEvents(clean = false) {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = direction === "right"
          ? document.body.scrollWidth - e.clientX
          : e.clientX;
        el.style.width = f + "px";
      }

      if (clean) {
        drawerBorder.removeEventListener("mousedown", this.handleMouseDown, false);
        document.removeEventListener("mouseup", this.handleMouseUp, false);
      } else {
        drawerBorder.addEventListener(
          "mousedown",
          function(e) {
            if (e.offsetX < minSize) {
              el.style.transition ='initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
        );
    
        document.addEventListener(
          "mouseup",
          function() {
            el.style.transition ='';
    
            if(window.innerWidth < 960){
              vm.navigation.width = parseInt(window.innerWidth);
            } else {
              vm.navigation.width = el.style.width;
            }
    
            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);
    
            localStorage.setItem("drawerWidth", vm.navigation.width);
          },
          false
        );
      }
    },
    //Panel resize end

    resetPagination() {
      this.$refs.DynamicTable.resetPagination();
    },

    /**
     * @name changeDefaultPerPage
     * @param value
     * @description change default page in pagination for child
     */
    changeDefaultPerPage(value) {
      this.defaultPerPage = value;
      this.dataInitial.offset = 0;
      this.dataInitial.limit = this.defaultPerPage;
      this.getItems();
    },

    /**
     * @method
     * @name changePage
     * @description server side Pagination
     * @param value
     */
    changePage(value) {
      this.dataInitial.offset = value > 1 ? (this.defaultPerPage * value) - this.defaultPerPage : 0;
      this.getItems();
    },

    /**
     * @name loadData
     * @description trigger axios on tab with tables changed
     * @param item
     * @returns {Promise<void>}
     */
    async loadData(item) {
      this.multiple_entity = item.name
      this.model_description = item.description
      this.entity_fields = item.columns_json
    },

    async applyFilter(clearSearch = false) {
      if(clearSearch) this.searchInTable = ""
      this.dataInitial = JSON.parse(JSON.stringify(this.dafaultDataInitial));
      this.dataInitial.limit = this.defaultPerPage;
      await this.$refs.DynamicTable.resetPagination()
      await this.getItems()
    },

    /**
     * @name getItems
     * @description main trigger function for Get items
     * @returns {Promise<void>}
     */
    async getItems() {
      if(this.$refs && this.$refs.DynamicStatistics) {
        this.$refs.DynamicStatistics.getCustomEntityStatistics();
      }
      this.entity_data = await this.getCustomEntityList();
    },

    /**
     * @name getCustomEntityList
     * @description axios for get Items
     * @param multiple_entity
     * @returns {Promise<null>}
     */
    async getCustomEntityList() {
      let data = null;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/list`

      const params = {
        limit: this.dataInitial?.limit,
        offset: this.dataInitial?.offset,
        order: {
          [this.dataInitial?.order?.sortBy]: this.dataInitial?.order?.sortDesc ? 'desc' : 'asc'
        },
      }

      if(this.searchInTable) params['search'] = this.searchInTable;

      //set filters
      if(Object.keys(this.custom_data_selected_filters).length !== 0) {
        params['filter'] = this.setFiltersObject()
      }

      // this.$store.dispatch('updateAjaxDialog', [true, true]);
      this.loading = true

      await axios({
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': token,
        },
        url: url,
        params: {
          bot_id: this.$route?.params?.id,
        },
        data: params,
      })
        .then( (response) => {
          // this.$store.dispatch('updateAjaxDialog', [false, false]);
          this.loading = false

          if ( response?.data?.status === 'success' ) {
            const columns = response.data.data.columns;
            data = response.data.data.rows;
            this.length = response.data.data.count;
            this.pageCount = Math.ceil(response.data.data.count / this.defaultPerPage);

            //set headers without association columns
            this.headers = []
            for (let c in columns) {
              let item = {
                align: 'start',
                name: columns[c].name,
                rules: [],
                show_in_popup: true,
                show_in_table: true,
                hidden_in_table: columns[c].hidden_in_table,
                hidden_all: columns[c].hidden_all,
                sortable: columns[c].sortable,
                text: columns[c].name === 'createdAt' ? this.$t('modules.customdata.table.created_at') : (columns[c]?.label || columns[c].name),
                value: columns[c].name,
                type: columns[c].type,
                width: columns[c].width,
                sum: columns[c]?.sum,
                non_editable: columns[c]?.non_editable,
                display_type: columns[c]?.display_type,
                click_action: columns[c]?.click_action,
                file_upload: columns[c]?.file_upload
              }

              if (!columns[c]?.association) {
                this.headers.push(item);
              }
            }

            this.headers.push({
              text: this.$t('modules.customdata.table.column_actions'),
              value: 'actions',
              type: 'actions',
              sortable: false,
              width: '110',
            });

             // set default columns for columns_chooser
            this.$store.dispatch('customDataSetDefaultColumnsData', this.headers)

            //operations with headers and columns_chooser
            if(this.custom_data_columns_data_activated.length) {
              this.$store.dispatch('customDataSetColumnsDataFromActivated')
            } else {
              if (this.custom_data_saved_columns_data.length) {
                this.$store.dispatch('customDataSetColumnsData', this.custom_data_saved_columns_data)
              } else {
                this.$store.dispatch('customDataSetColumnsData', this.headers)
              }
            }
          }
        },
        (err) => {
          this.$store.dispatch('updateAjaxDialogError', [ err, 'Ошибка', 'обновления' ]);
        }
      );

      return data;
    },

    /**
     * Save button in edit modal
     * @method save
     */
    async save() {
      if (this.$refs.form.validate()) {
        let request = this._.cloneDeep(this.item_obj);

        if (!request.id) {
          request.id = null;
        } else if (request.id && request.id === '') {
          request.id = null;
        }

        if (!request.kw_user_id) {
          request.kw_user_id = null;
        } else if (request.kw_user_id && request.kw_user_id === '') {
          request.kw_user_id = null;
        }

        for(let i in request) {
          if (typeof request[i] === 'object') {
            request[i] = request[i]?.id || null
          }

          if(!request[i]) {
            for (let headerItem of this.headers) {
              if (headerItem.file_upload === true && headerItem.name === i) {
                delete request[i];
              }
            }
          }

        }

        if (await this.saveCustomEntityList(request, request.id)) {
          this.getItems();
          this.modal = false;
          this.$refs.form.reset()
        }
      } else {
        return false;
      }
    },

    /**
     * @method saveCustomEntityList
     * @param entity
     * @param request
     * @param id
     * */
    async saveCustomEntityList(request, id) {
      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;

      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/update/${id}/?bot_id=${bot_id}`
      if(id===null)
        url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/create/?bot_id=${bot_id}`

      this.$store.dispatch('updateAjaxDialog', [true, true]);

      await axios
        .post(
          url,
          request,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            },
          }
        )
        .then(
          (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data &&
              response.data.status &&
              response.data.status === 'success'
            ) {
              success = true;
            }
          },
          (err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message_alias
            ) {
              let message_alias = err.response.data.message_alias;

              if (message_alias === 'access_error') {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_permitions'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_save'),
                ]);
              }
            } else if(
                err &&
                err.response &&
                err.response.data &&
                err.response.data.message.original.code === 'ER_WARN_DATA_TRUNCATED'
              ) {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_type'),
                ]);
              } else if(
                err &&
                err.response &&
                err.response.data &&
                err.response.data.message.original.code === 'ER_DATA_TOO_LONG'
              ) {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('rules.stringMaxLength'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_save'),
              ]);
            }
          }
        );

      return success;
    },

    /**
     * Edit item button
     * @method editItem
     * @param id
     * */
    async editItem(id) {
      //reset form
      if (this.$refs && this.$refs.form) {
        this.$refs.form.reset();
      }

      if (this.entity_data && id) {
        for (let item of this.entity_data) {
          if (item.id && parseInt(item.id) === parseInt(id)) {
            for (let param in item) {
              Vue.set(this.item_obj, param, item[param]);
            }

            this.openModal ()
          }
        }
      }
    },

    /**Delete item button
     * @method deleteItem
     * @param id item id
     * */
    async deleteItem(id) {
      if (confirm(i18n.t('modules.customdata.entity.confirm_delete'))) {
        if (await this.deleteCustomEntityList(id)) {
          this.getItems();
        }
      }
    },

    /**Delete item button from Drawer
     * @method deleteItemDrawer
     * */
     async deleteItemDrawer() {
      let request = this._.cloneDeep(this.item_obj);

      if (confirm(i18n.t('modules.customdata.entity.confirm_delete'))) {
        if (await this.deleteCustomEntityList(request.id)) {
          this.getItems();
          this.modal = false;
          this.$refs.form.reset()
        }
      }
    },

    /**Delete selected items
     * @method deleteSelectedItem
     * @param array
     * */
    async deleteSelectedItem(arr) {
      if (confirm(i18n.t('modules.customdata.entity.confirm_delete'))) {
        try {
          const deletePromises = arr.map(item => this.deleteCustomEntityList(item.id));
          await Promise.all(deletePromises);
          this.getItems();
          this.selected = [];
        } catch (error) {
          console.error(error);
        }
      }
    },

    /**
     * Delete entity from the list
     * @method deleteCustomEntityList
     * @param entity
     * @param id
     */
    async deleteCustomEntityList(id) {
      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/delete/${id}/?bot_id=${bot_id}`

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios
        .delete(
          url,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            }
          }
        )
        .then(
          (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data &&
              response.data.status &&
              response.data.status === 'success'
            ) {
              success = true;

              // this.$store.dispatch('axiosGetCurrentSectionNodesData');
            }
          },
          (err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message_alias
            ) {
              let message_alias = err.response.data.message_alias;

              if (message_alias === 'access_error') {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_permitions'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_delete'),
                ]);
              }
            } else {
              this.$store.dispatch('updateAjaxDialogError', [
                err,
                this.$t('common.error'),
                this.$t('common.error_delete'),
              ]);
            }
          }
        );

      return success;
    },

    /**
     * Export of custom data entities in file
     * @method getExportFile
     */
    getExportFile(fileType) {
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/export_data`

      //set filters
      const queryData = {}
      if(Object.keys(this.custom_data_selected_filters).length !== 0) {
        queryData['filter'] = this.setFiltersObject()
      }

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      axios({
        method: 'POST',
        url: url,
        headers: {
          'X-CSRF-TOKEN': token,
        },
        params: {
          bot_id: bot_id,
          format: fileType,
          filename: 'bot_' + this.multiple_entity + '-' + bot_id,
        },
        data: queryData,
      })
        .then( (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            if(response?.status === 200) {
              this.$store.dispatch('updateAjaxDialog',
                [
                  true,
                  false,
                  '',
                  response?.data?.message_alias ?
                    this.$i18n.t(`notification.${response.data.message_alias}`)
                    : response?.data?.message,
                ]
              );
            }

            // this.$forceFileDownload(response, 'custom_data_' + this.multiple_entity + '-' + bot_id + '.' + fileType)
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [ err, 'Ошибка', 'Скачивания' ]);
          }
        );

    },

    /**
     * @name clearModel
     * @description Clear all row in table
     * @returns {Promise<void>}
     */
    async clearModel(){
      if (confirm(i18n.t('modules.customdata.model.confirm_clear'))) {
        await this.CustomDataAxiosClearModel({name: this.multiple_entity})
        this.getItems()
      }
    },

    formatDateToString(date) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },

    formatDate(isoTimeString, short = false, shortest = false) {
        if (!isoTimeString) return;
        const date = new Date(isoTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        if (short) {
            return `${day}.${month}.${year.toString().slice(-2)} ${hours}:${minutes}`;
        } else if (shortest) {
            return `${day}.${month}.${year}`;
        } else {
            return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
        }
    },

    openModal(createMode) {
      (createMode) ? this.modal_create_mode = true : this.modal_create_mode = false
      this.modal = true
    },

    /**
     * @name setFiltersObject
     * @description prepare Filters from selected and return URL
     */
    setFiltersObject () {
      let objectFilters = {}
      let requestFilter = Object.entries(this._.cloneDeep(this.custom_data_selected_filters))
        .filter(item => item[1]?.value)

      for (const [key, params] of requestFilter) {
        const pKey = key
        const type = params.type
        const association = params.is_association

        let condition = params.condition?.value
        let value = params.value

        switch (condition) {
          case 'empty':
            condition = 'eq'
            value = 'null'
            break;

          case 'like':
            condition = 'like'
            value = '%' + value + '%'
            break;

          case 'startsWith':
            condition = 'like'
            value = value + '%'
            break;

          case 'endsWith':
            condition = 'like'
            value = '%' + value
            break;

          case 'notLike':
            condition = 'notLike'
            value = '%' + value + '%'
            break;

          case 'active':
            condition = 'eq'
            value = 1
            break;

          case 'notActive':
            condition = 'eq'
            value = 0
            break;

          default:
            this.$consoleLogHandler('filter conditions no changed')
        }

        if (association) {
          if (value.length > 0) {
            objectFilters[pKey] = [];
            value.map(function(elVal) {
              objectFilters[pKey].push(elVal?.id);
            })
          }
        } else {
          if( (type === 'STRING' || type === 'TEXT') && value !== undefined) {
            objectFilters[pKey] = {[condition]: value}

            // if (condition === 'multiple' && value.length > 0) {
            //   value.map(function(elVal) {
            //     url += '&filter[' + pKey + '][in][]=' + elVal;
            //   })
            // } else {
            //   url += '&filter[' + pKey + '][' + condition + ']='+ value;
            // }
          } else if (type === 'INTEGER' && value !== undefined) {
            objectFilters[pKey] = {[condition]: value}

            // if ( (condition === 'between' || condition === 'notBetween') && value.length > 0 ) {
            //   value.map(function(elVal) {
            //     url += '&filter[' + pKey + '][' + condition + '][]=' + elVal;
            //   })
            // } else {
            //   url += '&filter[' + pKey + '][' + condition + ']=' + value;
            // }
          } else if (type === 'FLOAT' && value !== undefined) {
            objectFilters[pKey] = {[condition]: value}
            // if ( (condition === 'between' || condition === 'notBetween') && value.length > 0 ) {
            //   value.map(function(elVal) {
            //     url += '&filter[' + pKey + '][' + condition + '][]=' + elVal;
            //   })
            // } else {
            //   url += '&filter[' + pKey + '][' + condition + ']=' + value;
            // }
            // url += '&filter[' + pKey + '][like]=' + value;
          } else if (type === 'BOOLEAN' && value !== undefined && value !== null) {
            objectFilters[pKey] = {[condition]: value}
            // url += '&filter[' + pKey + '][' + condition + ']=' + value;
          } else if (type === 'DATE' && value !== undefined && value.length > 0) {

            //fix if one date in range v-date-picker
            if(value.length < 2){
              let secondDate = new Date(value[0])
              value.push(
                this.formatDateToString(
                  secondDate.setDate( secondDate.getDate() + 1) )
              )
            }

            //rise second date for back request
            if(value.length === 2) {
              let secondDate = new Date(value[1])
              value[1] = this.formatDateToString( secondDate.setDate( secondDate.getDate() + 1) )
            }

            objectFilters[pKey] = {gt: value[0], lt: value[1]}

            // url += '&filter[' + pKey + '][gt]=' + value[0];
            // url += '&filter[' + pKey + '][lt]=' + value[1];
          }
        }
      }

      return objectFilters
    },

    async resetColumnsChooser() {
      this.headerColumns = []
      await this.customDataResetColumnsDataToDefault(this.multiple_entity)
      this.setHeaderColumnsFromState()
      // this.headerColumns = this._.cloneDeep(this.custom_data_columns_data)
    },

    async resetColumnsChooserForFilter() {
      this.headerColumns = []
      await this.customDataResetColumnsDataForFilterToSaved()
      this.setHeaderColumnsFromState()
      // this.headerColumns = this._.cloneDeep(this.custom_data_columns_data)
    },

    /**
     * @name onSaveFilters
     * @description save and update filters
     * @param data
     * @returns {Promise<void>}
     */
    async onSaveFilters(data){
      let { name, with_columns } = data
      let selectedFilters = Object.entries(this._.cloneDeep(this.custom_data_selected_filters))
        .filter(item => item[1]?.value)

      await this.customDataAxiosSaveFilters(
        {
          nameModule: this.multiple_entity,
          nameFilter: name,
          filters_json: selectedFilters,
          with_columns: with_columns,
        }
      )
    },

    /**
     * @name onDeleteFilter
     * @description delete filter
     * @param name
     */
    onDeleteFilter(name){
      this.$store.dispatch('customDataAxiosDeleteFilter',
        {
          nameModule: this.multiple_entity,
          nameFilter: name,
        }
      )
      this.setHeaderColumnsFromState();
    },

    setHeaderColumnsFromState() {
      let columns = this._.cloneDeep(this.custom_data_columns_data)

      this._.orderBy(columns, 'position')
      this.headerColumns = columns
    },

    returnFilterKeyOrLabel(key, item) {
      return item?.label ? item?.label : key
    },

    changeTabRoute(item) {
      if(this.$route?.params?.model_name !== item.name)
        this.$router.push({ params: { model_name: item.name } });
    },

    handleSearchInput(onEnter) {
      if (this.timeoutId) clearTimeout(this.timeoutId);

      (onEnter)
        ? this.getItems()
        : this.timeoutId = setTimeout(this.getItems, 2000)
    },

    scrollToComponent() {
      setTimeout(()=>{
        const component = this.$refs.DynamicStatistics;
        component?.$el.scrollIntoView({ behavior: 'smooth' });
      },500) 
    },

    getItemObjValueForAssociation(key) {
      let item =  this.custom_data_linked_tables_values[key].filter((i) => {
        if (i.name === this.item_obj[key]){
          this.item_obj[key] = JSON.parse(JSON.stringify(i))
          return i
        } else if (i.name === this.item_obj[key]?.name) {
          return this.item_obj[key]
        }
      })

      return item[0]
    },
    setItemObjValueForAssociation(key, value) {
      (value) ? this.item_obj[key] = value : delete this.item_obj[key]
    },

    disableSearchActive() {
      setTimeout(() => {
        this.isSearchActive = false;
      }, 500);
    },

    openFileDialog(event) {
      const previewLink = event.target.closest('a.file-item-link');
      if (!previewLink) {
        event.target.closest('.custom-data__file').querySelector('.fileInput label').click();
      }
    },

    isImage(file) {
      const fileName = file.toLowerCase();
      return (fileName.endsWith('.jpg') ||
              fileName.endsWith('.jpeg') ||
              fileName.endsWith('.png') ||
              fileName.endsWith('.gif') ||
              fileName.endsWith('.bmp') ||
              fileName.endsWith('.svg'));
    },

    /**
     * Upload file
     * @method uploadFile
     *
     * */
     async uploadFile(id, data, field_name) {

      if (data.length === 0) return false;

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/update_file/${id}`;
      const request = new FormData();

      let i = 0;
      for (let file of data) {
        request.set('files[' + i + ']', file, file.name);
        i++;
      }

      request.append('field_name', field_name);

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios.post(url, request, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "X-CSRF-TOKEN": token
        },
        params: {
          bot_id: bot_id,
        },
      })
      .then( async (response) => {
        this.$store.dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.uploaded') ]);

        if ( response?.data?.status === 'success' ) {
          this.item_obj[field_name] = {files: []}
          const updatedFiles = response.data.data[field_name].files;
          this.item_obj[field_name].files = updatedFiles;
          this.getItems();
          this.uploadFiles = [];
          success = true;
        }
      },
        (err) => {
          this.$store.dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
        }
      );

      return success;
    },

    /**
    * Delete file
    * @method deleteFile
    *
    * */
    async deleteFile(file_name, id, field_name) {

      if (!file_name) return false;

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/delete_file/${id}`;

      const request = {
        field_name: field_name,
        file_name: file_name
      };

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios.post(url, request, {
        headers: {
          'Content-Type': 'application/json',
          "X-CSRF-TOKEN": token
        },
        params: {
          bot_id: bot_id,
        },
      })
      .then( async (response) => {
        this.$store.dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.deleted') ]);

        if ( response?.data?.status === 'success' ) {
          const updatedFiles = this.item_obj[field_name].files.filter(file => file.name !== file_name);
          Vue.set(this.item_obj[field_name], 'files', updatedFiles);
          this.getItems();
          success = true;
        }
      },
        (err) => {
          this.$store.dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
        }
      );

      return success;
    },

  },

  watch: {
    modal: function (val) {
      if (!val && this.item_obj?.id) {
        this.$refs.form.resetValidation();
        this.item_obj = this._.cloneDeep(this.item_empty_obj);
      }
    },

    multiple_entity: async function (value) {
      //clear headers
      this.headerColumns = []
      this.dataInitial = JSON.parse(JSON.stringify(this.dafaultDataInitial));
      this.$store.dispatch('customDataSetColumnsData', [])
      this.$store.dispatch('customDataSetColumnsDataFromActivated', 'clear')

      this.$store.dispatch('customDataClearLinkedTableValues');

      //get filters
      await this.$store.dispatch('customDataAxiosGetFilters', {
        multiple_entity: value,
        botId: this.$route?.params?.id
      })

      //get rows and headers
      this.entity_data = await this.getCustomEntityList();

      // this.headerColumns = await this._.cloneDeep(this.custom_data_columns_data)
      this.setHeaderColumnsFromState()

      this.dynamicStatisticsShowed = false;
      this.dynamicStatisticsAvailable = !!this.custom_data_tables.find((t) => t.name === value && t?.statistics_json?.length);

      //show table
      if (!this.load) this.load = true;
    },
  },
};
</script>

<style scoped lang="scss">
.app-wrapper > div > div > div:not(.v-overlay__content) {
  height: auto;
}

.page-title {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
}

.cd {
  &__container {
    padding: 16px 12px 22px;
    display: flex;
    flex-direction: column;

    height: calc(100vh - 60px);
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      height: calc(100svh - 60px);
    }

    &.statistics-showed {
      height: auto;
    }
  }

  &__left {
    min-width: 216px;
    max-width: 216px;
    width: 216px;
    padding-left: 8px;
    padding-right: 8px;
    height: 100%;
  }

  &__right {
    padding-left: 8px;
    padding-right: 8px;
    flex: 1;
    height: 100%;
    width: calc(100% - 216px);
    position: relative;

    .table-descr {
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      top: -35px;
      left: 10px;
      color: var(--v-primary-base)
    }
  }
}

/* Top navigation for Table */
.cd__top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  .cd__right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 0.5em;
  }

  .cd__actions {
    display: flex;
  }

  .cd__tabs {
    &:after {
      content: '';
      display: block;
      height: 1px;
      background: var(--v-blueHaze-base);
      margin-right: 52px;
      margin-left: 52px;
      margin-top: -1px;
    }
    


    .v-tab {
      border-bottom: 1px solid var(--v-blueHaze-base);
      font-size: 0.875rem;
      padding: 0 10px;
      text-transform: none;
    }
  }

  .v-input {
    max-width: 360px;
  }

  .v-btn::v-deep {
    border-radius: 5px;
    padding: 0 10px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0.04em;
  }

  .v-select {
    max-width: 134px;
    font-size: 14px;
  }
}

/* end Top navigation for Table */

/* Filters and Table */
.cd__bottom-nav {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
}

.cd__table {
  display: flex;
  flex-direction: column;
}

/* menus with list */
.v-custom-menu__list{
  border: 1px solid var(--v-gray-base);

  &--btn {
    &:focus:before {
      opacity: 0;
    }
    &[aria-expanded="true"] {
      .v-icon {
        color: var(--v-primary-base) !important;
      }
    }

    &.v-btn--text{

      &[aria-expanded="true"] {
        .v-btn__content {
          color: var(--v-primary-base) !important;
        }

        .v-icon.mdi-chevron-down {
          transform: rotate(180deg);
        }
      }
    }
  }

  &--wrapper::v-deep {
    &.with_fixed-height {
      //height: 380px;
    }

    .__rail-is-vertical {
      right: 0 !important;
    }

    .v-list-group {
      &__items {
        background-color: var(--v-gray2-base) !important;

        .v-list-item__content {
          padding: 0 64px !important;
        }
      }
    }

  }

  .v-list {
    padding: 5px 0 10px;

    .v-subheader {
      height: auto;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 15px 20px 5px;
    }

    .v-list-item {
      //min-height: 32px;
      //max-height: 34px !important;

      &.v-list-item--active::before {
        opacity: 0;
      }

      .v-list-item__action{
        margin: 5px 12px 4px 0;
      }
      .v-list-item__content {
        padding: 6px 0;
        .v-list-item__title {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  &--filter-btns::v-deep {
    flex: none;
    transition: all 0.25s;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 5px;
      pointer-events: none;
      background-color: currentColor;
      opacity: 0;
    }

    &:hover {

      .theme--light.v-divider {
        border-color: white !important;
        z-index: 1;
      }

      &::before {
        opacity: 0.08;
      }
    }

    .theme--light.v-divider {
      border-color: var(--v-primaryBackground-base) !important;
    }

    &.active{
      border-color: var(--v-gray-base);

      &::before {
        opacity: 0.08;
      }
    }

    .v-btn {
      border: none !important;
      height: 100% !important;
      //min-width: inherit !important;
      //width: auto !important;
    }

    .filter-btns {
      &__btn-show {
        min-width: 36px !important;

        &:hover::before {
          opacity: 0;
        }
      }
      &__btn-menu {
        //flex: auto !important;
        min-width: 28px !important;
        padding: 0 6px !important;

        &:focus::before {
          opacity: 0;
        }
      }
    }
  }
}

.draggable-ghost {
  opacity: 0.5;
  background: var(--v-primaryLight-base);
}
/* end menus with list */

.settings-form::v-deep {
  height: 100%;

  .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .v-card__text{
      flex-grow: 1;
      overflow: auto;
    }
  }
}

.modal__close-btn {
  z-index: 1;
  top: 9px;
}

.modal__menu-btn {
  z-index: 1;
  top: 48px;

  &::before {
    opacity: 0.08;
  }
}

.v-navigation-drawer {
  &::v-deep .v-navigation-drawer__border {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 0, 0, 0.08) !important;
    
    &:after {
      content: '·······';
      color: #000;
      transform: rotate(90deg);
      position: absolute;
      top: 50%;
      left: -18px;
      font-size: 24px;
      line-height: 0.3;
      pointer-events: none;
    }

    @media screen and (max-width: 959px){
      display: none;
    }
  }

  .navigation-drawer__title {
    font-size: 18px;
    margin-top: 30px;
    padding-left: 27px;
    padding-right: 50px;
    padding-bottom: 10px;
    text-align: left;
  }
}

.custom-data__search::v-deep {

  .v-input {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 5px;
      pointer-events: none;
      background-color: currentColor;
      opacity: 0;
      transition: all 0.25s;
    }

    &:hover::before {
      opacity: 0.08;
    }

    &--is-focused {
      width: 100%;

      .v-input__slot {
        cursor: text;
        
        &:after {
          width: 100%;
        }
      }

      input {
        cursor: text !important;
        width: 165px !important;
      }
    }
  }

  .v-input__slot {
    cursor: pointer;
    padding: 0 10px 0 5px;

    &::before {
      content: none;
    }

    &:after {
      border-bottom-width: 0;
      transform: none;
      width: 0;
    }

    input {
      cursor: pointer;
      width: 45px;
      transition: all 0.4s;
    }
  }

  .v-icon {
    font-size: 18px;
    color: inherit;
  }

  .v-text-field__slot > * {
    color: inherit;
    font-size: 13px;
  }
}

.col-chooser-btn-wrap {
  position: sticky;
  bottom: 0;
  background: #fff;
  background: linear-gradient(0deg, #fff 80%, transparent 100%);
}

.custom-data__file {

  &--item {
    border: 1px solid rgba(0, 0, 0, .38);
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    min-height: 40px;

    .file-item-link {
      position: relative;
    }

    .file-item-del {
      position: absolute;
      top: -3px;
      right: -3px;
      opacity: 0.8;
      transition: all 0.25s;

      &:hover {
        opacity: 1;
      }
    }
  }

  &--label {
    text-align: left;
    font-size: 16px;
  }

}

</style>

import LAYOUT from '@/layout/layout';
import moduleLayout from '@/views/Dialog/Dialog.vue';

export default [
  {
    path: '/dialog',
    component: LAYOUT.base,
    // redirect: '/dialog/scenario',
    children: [
      // {
      //   path: 'scenario',
      //   component: moduleLayout,
      //   children: [
      //     {
      //       path: '',
      //       name: 'DialogScenario',
      //       component: () => import(/* webpackChunkName: 'dialog-scenario' */ '@/views/Dialog/tabs/DialogTab.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: 'constants',
      //   component: moduleLayout,
      //   children: [
      //     {
      //       path: '',
      //       name: 'DialogConstants',
      //       component: () =>import(/* webpackChunkName: 'dialog-constants' */ '@/views/Dialog/tabs/ConstantsTab.vue'),
      //     },
      //   ],
      // },
      {
        path: 'bot-scenario/:id',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'bot_scenario',
            component: () => import(/* webpackChunkName: 'dialog-scenario' */ '@/views/Dialog/tabs/DialogTab.vue'),

						children: [
							{
								path: 'history/:scenario_id',
								name: 'bot_scenario_history'
							}
						],
          },
        ],
      },
    ],
  },
];

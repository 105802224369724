<template>
  <div class="filter-nav" v-if="innerSelectedFilters.length || savedFilters.length">
    {{ /* Toggle Active Filters */ }}
    <div
      class="text-caption font-weight-bold mb-2"
      v-if="toggleActiveFilter"
    >
      {{$t("modules.customdata.entity.active_filter")}}:
      <span class="primary--text">"{{toggleActiveFilter.name}}"</span>
    </div>
<!--    <div class="filter-nav__saved" v-if="savedFilters.length">-->
<!--      <v-btn-toggle-->
<!--        class="filter-nav__saved-group"-->
<!--        v-model="toggleActiveFilter"-->
<!--        group-->
<!--      >-->
<!--        <v-btn-->
<!--          height="26"-->
<!--          v-for="(item, i) in savedFilters"-->
<!--          :key="i"-->
<!--          :value="item.name"-->
<!--          @click="clickActiveFilter(item)"-->
<!--        >-->
<!--          {{ item.name }}-->
<!--        </v-btn>-->
<!--      </v-btn-toggle>-->
<!--    </div>-->
    {{ /* end Toggle Active Filters */ }}

    {{/* Selected Filters */}}
    <template v-if="innerSelectedFilters.length">
      <div class="filter-nav__selected">
        <div
          v-for="(item, i) in innerSelectedFilters"
          :key="i"
          class="mb-2"
        >
          <div class="text-caption font-weight-bold">{{returnFilterKeyOrLabel(item)}}</div>
          <div
            v-if="item.condition && item.type !== 'BOOLEAN'"
            class="text-caption gray--text"
          >
            <span class="font-weight-bold">{{$t('modules.customdata.filters.condition')}}:</span>
            {{item.condition.text}}
          </div>
          <template v-if="item.hasOwnProperty('association')">
            <v-chip
              v-for="(option, i) of item.value"
              :key="i"
              :color="toggleFiltersStyle() ? 'gray2' : 'primary'"
              :text-color="toggleFiltersStyle() ? 'primary' : 'white'"
              close
              label
              elevation="0"
              @click:close="deleteFilter(item, option)"
            >
              <span class="chipCutted" :title="option.name">{{option.name}}</span>
            </v-chip>
          </template>
          <template v-else-if="item.type === 'SELECT'">
            <v-chip
              v-for="(option, i) of item.value"
              :key="i"
              :color="toggleFiltersStyle() ? 'gray2' : 'primary'"
              :text-color="toggleFiltersStyle() ? 'primary' : 'white'"
              close
              label
              elevation="0"
              @click:close="deleteFilter(item, option)"
            >
              <span class="chipCutted" :title="option.name">{{option.name}}</span>
            </v-chip>
          </template>
          <template v-else-if="item.type === 'BOOLEAN'">
            <v-chip
              :key="i"
              :color="toggleFiltersStyle() ? 'gray2' : 'primary'"
              :text-color="toggleFiltersStyle() ? 'primary' : 'white'"
              close
              label
              elevation="0"
              @click:close="deleteFilter(item)"
            >
              <span class="chipCutted" :title="item.condition.text">{{item.condition.text}}</span>
            </v-chip>
          </template>
          <template v-else>
            <template v-if="checkValueIsArray(item.value)">
              <v-chip
                v-for="(option, i) of item.value"
                :key="i"
                :color="toggleFiltersStyle() ? 'gray2' : 'primary'"
                :text-color="toggleFiltersStyle() ? 'primary' : 'white'"
                close
                label
                elevation="0"
                @click:close="deleteFilter(item, option)"
              >
                <span class="chipCutted" :title="option">{{option}}</span>
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                :key="i"
                :color="toggleFiltersStyle() ? 'gray2' : 'primary'"
                :text-color="toggleFiltersStyle() ? 'primary' : 'white'"
                close
                label
                elevation="0"
                @click:close="deleteFilter(item)"
              >
                <span class="chipCutted" :title="item.value">{{item.value}}</span>
              </v-chip>
            </template>

          </template>
        </div>

      </div>
    </template>
    {{/* end Selected Filters */}}

    <div class="filter-nav__actions">

      {{/* Filter Saving */}}
      <v-dialog
        v-model="showSaveDialog"
        width="480"
        content-class="filter-nav__overlay"
        v-if="innerSelectedFilters.length && !custom_toggle_active_filter && canSave"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            height="36"
            x-small
            elevation="0"
            color="primary"
            outlined
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('modules.customdata.filters.save_filters') }}
          </v-btn>
        </template>

        <v-card>
          <v-btn
            icon
            class="btn-close"
            @click="showSaveDialog = false"
          >
            <v-icon
              color="black"
              size="28"
            >mdi-close
            </v-icon>
          </v-btn>

          <v-card-title>
            {{ $t('modules.customdata.filters.save_filters') }}
          </v-card-title>

          <v-card-text>
            <v-text-field
              :label="$t('modules.customdata.filters.label_new_filters')"
              :placeholder="$t('modules.customdata.filters.label_new_filters')"
              outlined
              dense
              height="48"
              hide-details
              v-model="filterTitle"
            ></v-text-field>

            <v-checkbox
              v-model="saveFilterWithColumns"
              :label="$t('modules.customdata.columns_chooser.save_filter_with_columns')"
              hide-details
            ></v-checkbox>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              @click="saveFilter"
              block
              height="44"
              elevation="0"
              :disabled="isDisable"
            >
              {{ $t('modules.customdata.filters.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
      {{/* end Filter Saving */}}

      {{/* Save Changed Filters */}}
      <v-btn
        height="36"
        elevation="0"
        color="primary"
        x-small
        outlined
        v-if="innerSelectedFilters.length && custom_toggle_active_filter && canSave"
        @click="saveChangedFilter"
      >
        {{ $t('modules.customdata.filters.save_changes') }}
      </v-btn>
      {{/* end Save Changed Filters */}}

      {{/* clear filters */}}
      <v-btn
        height="20"
        elevation="0"
        color="red"
        text
        plain
        :ripple=false
        v-if="innerSelectedFilters.length"
        @click="clearAllFilters"
      >
        {{ $t('modules.customdata.filters.clear_all') }}
      </v-btn>
      {{/* end clear filters */}}

      {{/* Delete Active Filter Title */}}
<!--      <v-btn-->
<!--        height="20"-->
<!--        elevation="0"-->
<!--        color="red"-->
<!--        text-->
<!--        plain-->
<!--        :ripple=false-->
<!--        @click="deleteSavedFilter"-->
<!--        v-if="custom_toggle_active_filter"-->
<!--      >-->
<!--        {{ $t('modules.customdata.filters.delete') }} "{{ custom_toggle_active_filter }}"-->
<!--      </v-btn>-->
      {{/* end Delete Active Filter Title */}}
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DynamicFiltersSave",

  props: {
    selectedFilters: {
      type: Array,
      default: () => [],
    },
    savedFilters: {
      type: Array,
      default: () => [],
    },
    custom_selected_filters: {
      type: Object,
      default: () => {},
    },
    custom_toggle_active_filter: {
      type: Object,
      default: () => {},
    },
    action_toggle_active_filter: {
      type: String,
      default: ''
    },
    action_delete_selected_filter_in_array: {
      type: String,
      default: ''
    },
    canSave: {
      type: Boolean,
      default: true,
    }
  },

  data: () => ({
    innerSelectedFilters: [],
    showSaveDialog: false,
    filterTitle: '',
    isDisable: true,
    // toggleActiveFilter: null,

    selectedSavedFiltersNames: [],
    currentFilterId: null,
    currentFilterName: null,

    saveFilterWithColumns: false,
  }),

  mounted() {
    this.createInnerSelectedFilters();
  },

  computed: {
    ...mapGetters([]),

    toggleActiveFilter: {
      get: function() {
        return this.custom_toggle_active_filter
      },
      set: function(value) {
        this.$store.dispatch(this.action_toggle_active_filter, value)
      },
    },
  },

  watch: {
    selectedFilters: function () {
      this.createInnerSelectedFilters();
    },

    filterTitle: function(value) {
      this.isDisable = value === '';
    },

    // savedFilters: function(currentVal) {
    //   const currentSelectedId = this.selectedSavedFiltersNames?.[0] ?? undefined;
    //   if (currentSelectedId && currentVal) {
    //     const filterGroup = currentVal?.find(({id}) => id === currentSelectedId);
    //     if (filterGroup) {
    //       this.selectedSavedFiltersNames = [];
    //       this.toggleActiveFilter = filterGroup.name;
    //       this.clickActiveFilter(filterGroup, filterGroup.id, filterGroup.name);
    //     }
    //   }
    // },
  },

  methods: {

    /**
     * @return {value: String, name: String, filter: Object}
     */
    createInnerSelectedFilters() {
      this.innerSelectedFilters = this.selectedFilters.flatMap((item) => {
        const name = Object.keys(item)[0]
        const type = Object.values(item)[0].type
        const condition = Object.values(item)[0]?.condition
        const association = Object.values(item)[0]?.is_association
        const optionValue = Object.values(item)[0]?.value

        if (association) {
          if (optionValue !== null) {
            if ( optionValue.length ) {
              return {
                name: name,
                label: Object.values(item)[0]?.label || null,
                type: type,
                condition: condition,
                value: optionValue,
                association: true,
              };
            }
          }
        } else {
          if (
            (type === 'INTEGER' || type === 'FLOAT' || type === 'BOOLEAN' || type === 'STRING' || type === 'TEXT')
            && optionValue
            && !association
          ) {
            return {
              name: name,
              label: Object.values(item)[0]?.label || null,
              type: type,
              condition: condition,
              value: optionValue,
            };
          }

          if ( type === 'SELECT' && optionValue !== null ) {
            return {
              name: name,
              label: Object.values(item)[0]?.label || null,
              type: type,
              condition: condition,
              value: optionValue,
            };
          }

          if ( type === 'DATE' && optionValue !== null ) {
            if (optionValue.length) {
              return {
                name: name,
                label: Object.values(item)[0]?.label || null,
                type: type,
                condition: condition,
                value: optionValue?.[1] ? `${optionValue[0]} | ${optionValue[1]}` : optionValue[0],
              };
            }
          }
        }

        return [];
      });
    },

    /**
     * @method
     * @name deleteFilter
     * @param filter
     * @param filterValue
     * @description delete filter by click
     */
    deleteFilter(filter, filterValue) {
      if (filterValue) filter.value = filterValue

      this.$store.dispatch(this.action_delete_selected_filter_in_array, {
        key: filter.name,
        value: filter.value
      })

      //apply getItems if no custom_selected_filters left
      let selectedFiltersLength = Object.entries(this.custom_selected_filters)
        .filter(item => item[1]?.value).length

      if (selectedFiltersLength < 1) {
        this.$emit('clear-all-filters');
      }
    },

    clearAllFilters() {
      this.$emit('clear-all-filters');
    },

    // deleteSavedFilter(){
    //   this.$emit('delete-saved-filter', this.currentFilterName);
    //   this.clickActiveFilter(null);
    // },

    saveChangedFilter() {
      let savedColumnsExist = this.custom_toggle_active_filter.columns_chooser

      this.$emit('save-filter', {
        name: this.custom_toggle_active_filter.name,
        with_columns: !!savedColumnsExist?.length
      });
      // this.$store.dispatch('customDataSetToggleActiveFilter', false)
    },

    saveFilter() {
      this.$emit('save-filter', {
        name: this.filterTitle,
        with_columns: this.saveFilterWithColumns
      });
      this.filterTitle = '';
      this.showSaveDialog = false;
      // this.$store.dispatch('customDataSetToggleActiveFilter', false)
    },

    /**
     * @method
     * @name toggleFiltersStyle
     * @description change color of chips in saved filter
     */
    toggleFiltersStyle() {
      if (this.custom_toggle_active_filter) return true;
    },

    checkValueIsArray(value) {
      return Array.isArray(value)
    },

    returnFilterKeyOrLabel(item) {
      return item?.label ? item?.label : item.name
    },
  }
};
</script>

<style lang="scss" scoped>
.filter-nav {
  padding: 0 12px 16px;

  &.bg_transparent {
    background-color: var(--v-primaryBackground-base);
  }
}

.filter-nav__saved {
  margin-bottom: 14px;

  &-group {
    display: flex;
    flex-wrap: wrap;

    &.v-btn-toggle--group .v-btn.v-btn {
      color: white;
      background-color: var(--v-black-base) !important;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-transform: inherit;
      border-radius: 2px !important;
      border: none;
      margin: 2px;
      padding-left: 8px;
      padding-right: 10px;

      &.v-item--active {
        background-color: var(--v-primary-base) !important;

        &:before {
          opacity: 0;
        }
      }
    }

    .v-btn__content .v-icon--right {
      margin-left: 4px;
    }
  }
}

.filter-nav__selected {
  margin-bottom: 14px;

  .v-chip {
    max-width: 170px;
    height: 28px;
    margin: 2px;
  }

  .v-btn__content .v-icon--right {
    margin-left: 4px;
  }
}

.filter-nav__actions {
  .v-btn {
    &.v-btn--text {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-transform: inherit;
      padding: 0;
      display: block;
      margin-top: 12px;
    }
  }
}

.filter-nav__overlay.v-dialog {
  .v-btn.btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .v-card {
    .v-card__title {
      padding: 32px 40px 20px;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }

    .v-card__text {
      padding: 12px 40px 12px;
    }

    .v-card__actions {
      padding: 12px 40px 40px;
    }

  }

}
.chipCutted{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

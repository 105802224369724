<template>
  <v-menu
    bottom
    left
    offset-y
    nudge-bottom="6"
    :dark="checkDarkThemeForRoute"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        icon
        color="primary"
        title="Помощь"
        class="mr-1"
      >
        <v-avatar
          height="28"
          width="28"
          min-width="28"
          color="primary"
        >
          <v-icon
            size="18"
            color="white"
          >
            mdi-help
          </v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in helpMenuItems"
        :key="index"
        dense
        class="px-2"
      >
        <v-btn
          :href="item.link"
          target="_blank" rel="noopener"
          text
          block
          color="primary"
          depressed
          class="text-none justify-start"
        >
          <v-icon left v-text="item.icon"/>
          {{ item.title }}

        </v-btn>
<!--        <v-list-item-icon>-->
<!--          <v-icon color="primary">{{ item.icon }}</v-icon>-->
<!--        </v-list-item-icon>-->
<!--        <v-list-item-content style="margin-left: -20px; margin-right: 10px;">-->
<!--          <v-list-item-title>-->
<!--            <a :href="item.link" target="_blank" rel="noopener" style="text-decoration: none">{{ item.title }}</a>-->
<!--          </v-list-item-title>-->
<!--        </v-list-item-content>-->
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import i18n from "@/lang/i18n";
import {mapGetters} from "vuex";

export default {
  name: 'MenuHelp',

  data: () => ({
    helpMenuItems: [
      {
        title: i18n.t('modules.dialog.texts.common.documentation'),
        link: i18n.t('documentation.documentation_notion_link'),
        icon: 'mdi-book'
      },
      {
        title: i18n.t('modules.dialog.texts.common.version_update'),
        link: i18n.t('version.notion_url'),
        icon: 'mdi-lightbulb'
      },
      // {
      //   title: 'Все ли работает?',
      //   link: '',
      //   icon: 'mdi-alert-outline'
      // },
      {
        title: i18n.t('modules.dialog.texts.common.support'),
        link: i18n.t('support.send_support'),
        icon: 'mdi-chat'
      }
    ],
  }),

  computed: {
    ...mapGetters([
      'opGetDarkThemeCookie',
    ]),

    checkDarkThemeForRoute() {
      return this.opGetDarkThemeCookie && this.$route?.name === 'OperatorPanelChats'
    },
  },
}
</script>
<template>
  <div v-if="checkAccess">
    <v-dialog
      v-model="dialog"
      width="700"
      max-width="90%"
      :persistent="false"
      :dark="checkDarkThemeForRoute"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 text-none"
          color="primary"
          v-bind="attrs"
          v-on="on"
          outlined
          small
          :ripple="false"
          @click="position = 0"
          :disabled="recording"
        >
          {{$t('is_convenient.is_it_convenient')}} 🤔
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <template v-if="position === 0">
            {{$t('is_convenient.title_0')}}
          </template>
          <template v-if="position === 1">
            {{$t('is_convenient.title_1')}}
          </template>

          <template v-if="position === 3">
            {{$t('is_convenient.title_3')}}
          </template>

          <template v-if="position === 4">
            {{$t('is_convenient.title_4')}}
          </template>

        </v-card-title>
        <v-card-text>
          <span
            v-if="position === 0"
            v-html="$t('is_convenient.text_0')"
          />
          <span
            v-if="position === 1"
            v-html="$t('is_convenient.text_1')"
          />
          <div v-if="position === 3">
            <div
              class="mb-2"
              v-html="$t('is_convenient.text_3')"
            />
            <video id="recorded-video" controls width="100%"></video>

            <v-textarea
              v-model="issueDescription"
              :label="$t('is_convenient.textarea_label')"
              outlined
              rows="3"
              clearable
              dense
              class="mt-4"
            />
          </div>

          <span
            v-if="position === 4"
            v-html="$t('is_convenient.alert_text_thanks')"
          />
        </v-card-text>
        <v-card-actions class="pb-4">
          <template v-if="position === 0">
            <v-btn
              color="primary"
              outlined
              @click="position = 4"
              small
              class="text-none"
            >
              {{$t('is_convenient.btn_0_like')}} 🤩
            </v-btn>
            <v-btn
              outlined
              color="primary"
              @click="position = 1"
              small
              class="text-none"
            >
              {{$t('is_convenient.btn_0_dislike')}} 🤯
            </v-btn>
          </template>

          <template v-if="position === 1">
            <v-btn
              outlined
              color="primary"
              @click="position = 2"
              small
              class="text-none"
            >
              {{$t('is_convenient.btn_1_start')}}
            </v-btn>
          </template>

          <template v-if="position === 3">
            <v-btn
              color="primary"
              outlined
              small
              @click="handleNewVideo()"
              class="text-none"
              :loading="saving"
            >
              {{$t('is_convenient.btn_3_send')}}
            </v-btn>
            <v-btn
              outlined
              color="primary"
              small
              @click="position = 1"
              class="text-none"
            >
              {{$t('is_convenient.btn_3_record_again')}}
            </v-btn>
          </template>

          <template v-if="position === 4">
            <v-btn
              outlined
              color="primary"
              @click="dialog = false; position = null"
              small
              class="text-none"
            >
              {{$t('is_convenient.btn_4_close')}}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import i18n from "@/lang/i18n";
import {mapGetters} from "vuex";
import RecordRTC from "recordrtc";
import axios from 'axios';
import Vue from "vue";

export default {
  name: 'IsConvenient',

  props: {
    recording: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    position: 0,
    dialog: false,

    recorder: null,
    recordedBlobs: null,
    videoSrc: null,
    audio: new Audio('https://bigsoundbank.com/UPLOAD/mp3/0307.mp3'),
    combinedStream: null,

    issueDescription: null,

    saving: false,
  }),

  computed: {
    ...mapGetters([
      'opGetDarkThemeCookie',
      'user',
      'engine_settings',
      'get_instance_settings',
    ]),

    checkDarkThemeForRoute() {
      return this.opGetDarkThemeCookie && this.$route?.name === 'OperatorPanelChats'
    },

    checkAccess() {
      const is_convenient_enabled = this.get_instance_settings.find((s) => s.key === 'is_convenient_enabled')?.value
      const has_access =  this.user?.role?.permissions?.extended_capabilities?.common
        ?.find(({name}) => name === 'is_it_convenient')?.select?.haveRights || this.user?.root

      return is_convenient_enabled && has_access
    }
  },

  methods: {
    async uploadFile() {
      if(!this.recordedBlobs) return

      const url = this.engine_settings.active_url;
      const token = Vue.$cookies.get('token_e');
      const formData = new FormData();
      formData.append("file", this.recordedBlobs, `recording_${Date.now()}.webm`);

      return axios
        .post(url + '/kw/filemanager/files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token,
          },
        })
        .then(
          async (response) => {
            if(response?.data?.status === 'success') {
              return response.data.data?.[0]
            }
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [
              err,
              this.$t('common.error'),
              this.$t('common.error_get'),
            ]);
          }
        );
    },

    async createIssue(videoUrl) {
      const url = this.engine_settings.active_url;
      const token = Vue.$cookies.get('token_e');
      const adfDescription = {
        type: 'doc',
        version: 1,
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: 'Дата записи: ' + new Date().toLocaleString(),
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: 'Проект: ',
              },
              {
                type:"inlineCard",
                attrs:{
                  url: window.location.origin
                }
              }
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: 'Пользователь: ' + this.user?.email,
              }
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: 'Видео доступно по ссылке: ',
              },
              {
                "type":"inlineCard",
                "attrs":{
                  "url": videoUrl
                }
              }
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: 'Комментарий пользователя: ',
                "marks": [
                  {
                    "type": "strong"
                  }
                ]
              },
              {
                "type":"text",
                text: this.issueDescription || '',
              }
            ],
          },
        ],
      };

      const projectName = this.get_instance_settings.find((s) => s.key === 'is_convenient_enabled')?.value
      const projectKey = this.get_instance_settings.find((s) => s.key === 'is_convenient_project_key')?.value
      const issueType = this.get_instance_settings.find((s) => s.key === 'is_convenient_issue_type')?.value

      const data = {
        summary: `[${projectName}] Нова задача з відео`,
        adfDescription: adfDescription,
        projectKey: projectKey || 'KWCC',
        issueType: issueType || 'Task',
      };

      return axios
        .post(url + '/kw/filemanager/create-jira-issue', data, {
          headers: {
            'X-CSRF-TOKEN': token,
          },
        })
        .then(
          async (response) => {
            return response
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [
              err,
              this.$t('common.error'),
              this.$t('common.error_get'),
            ]);
            return err
          }
        );
    },

    async handleNewVideo() {
      this.saving = true

      try {
        const videoUrl = await this.uploadFile();
        await this.createIssue(videoUrl)
          .then((response) => {
            if(response?.data?.key ) {
              this.position = 4;
            } else if(response?.response?.data?.errors) {
              this.$store.dispatch('updateAjaxDialogError', [
                response.response.data,
                this.$t('common.error'),
                JSON.stringify(response?.response?.data?.errors),
              ]);
            }
          });

      } catch (error) {
        this.$consoleLogHandler({text: 'Ошибка при создании задачи:', data: error})
      }

      this.saving = false
    },
  },

  watch: {
    position: function(val) {
      //init recording
      if(val === 2){
        this.$consoleLogHandler('Playing audio...');
        this.dialog = false
        this.audio.play();
        this.audio.onended = async () => {
          this.$consoleLogHandler('Audio ended, starting screen recording...');
          try {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });

            this.combinedStream = new MediaStream([...screenStream.getTracks(), ...audioStream.getTracks()]);

            this.recorder = new RecordRTC(this.combinedStream, {
              type: 'video',
              mimeType: 'video/webm;codecs=vp9'
            });

            this.recorder.startRecording();

            // this.recording = true;
            this.$emit('open-snack-bar', true)

          } catch (error) {
            await this.$store.dispatch('updateAjaxDialogError', [ error,  i18n.t("common.error"),  'Error accessing media devices.' ]);
          }
        };
      }

      //stop recording
      if(val === 3){
        this.$consoleLogHandler('Stopping recording...');
        this.recorder.stopRecording(() => {
          this.recordedBlobs = this.recorder.getBlob();
          // this.recording = false
          this.dialog = true
          document.getElementById('recorded-video').src = URL.createObjectURL(this.recordedBlobs);
          this.$consoleLogHandler('Recording stopped and preview displayed.');

          // Stop all tracks in the combined stream
          this.combinedStream.getTracks().forEach(track => track.stop());
        });
      }

      //reset all
      if(val === 4){
        // this.$emit('open-snack-bar', false)
        // this.recording = false
        this.recorder = null
        this.recordedBlobs = null
        this.videoSrc = null
        this.issueDescription = null
      }
    },

    recording: function(val) {
      if(!val && this.position === 2) {
        this.position = 3
      }
    }
  },
}
</script>

<style scoped lang="scss">
.top-info-snackbar {
  ::v-deep {
    .v-snack__content {
      padding: 0 20px;
    }
  }

}
</style>
import { render, staticRenderFns } from "./CreateAiMagicDataForm.vue?vue&type=template&id=342f3a8a&scoped=true"
import script from "./CreateAiMagicDataForm.vue?vue&type=script&lang=js"
export * from "./CreateAiMagicDataForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "342f3a8a",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"768","scrollable":"","fullscreen":_vm.dialogHistoryFullscreen},model:{value:(_vm.showHistory),callback:function ($$v) {_vm.showHistory=$$v},expression:"showHistory"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('modules.dialog.texts.history.modal_title_restore'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialogHistoryFullscreen = !_vm.dialogHistoryFullscreen;
          _vm.dialogHistoryFullscreen ? _vm.getInfinityScrollEntityList(_vm.$refs.InfiniteHistoryLoading.stateChanger) : null;}}},[_c('v-icon',[_vm._v("mdi-fullscreen")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.showHistory = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-simple-table',{staticClass:"table-infinity-scroll",attrs:{"fixed-header":"","height":_vm.dialogHistoryFullscreen ? 'calc(100vh - 90px)' : '300'},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('modules.dialog.texts.history.created_date'))+" ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('modules.dialog.texts.history.who_edit'))+" ")]),_c('th',{staticClass:"text-right",attrs:{"scope":"col"}})])]),(!_vm.loading)?_c('tbody',[_vm._l((_vm.entity_history_data),function(item,index){return _c('tr',{key:item._id,style:({
                backgroundColor: _vm.isCurrentScenario(item, index) ? '#5784eb' : null,
                color: _vm.isCurrentScenario(item, index) ? '#fff' : null,
              })},[_c('td',{staticClass:"text-caption"},[_vm._v(_vm._s(new Date(item.createdAt).toLocaleString()))]),_c('td',[_vm._v(_vm._s(item.constructor_user_email))]),_c('td',{staticStyle:{"text-align":"right"}},[(false && !_vm.isActualScenario(item))?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"success"}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e(),(
                    (!_vm.$route.params?.scenario_id && _vm.isActualScenario(item))
                    || _vm.isCurrentScenario(item)
                  )?[_c('v-chip',{staticStyle:{"color":"inherit"},attrs:{"small":"","outlined":"","rounded":""}},[_vm._v(_vm._s(_vm.$t('modules.dialog.texts.history.current')))])]:[(!_vm.isActualScenario(item))?[_c('v-btn',{staticClass:"text-none mr-2",attrs:{"elevation":"0","x-small":""},on:{"click":function($event){return _vm.activateItem(item, true)}}},[_vm._v(" "+_vm._s(_vm.$t('modules.dialog.texts.history.with_current'))+" ")])]:_vm._e(),_c('v-btn',{staticClass:"text-none",attrs:{"elevation":"0","color":_vm.isActualScenario(item)
                      ? 'success'
                      : 'primary',"x-small":""},on:{"click":function($event){_vm.isActualScenario(item)
                        ? ( _vm.showHistory = false, _vm.$router.push({
                          name: 'bot_scenario',
                          params: { id: _vm.$route.params.id }
                        }), _vm.reloadPage())
                        : _vm.activateItem(item)}}},[_vm._v(" "+_vm._s(_vm.isActualScenario(item) ? _vm.$t('modules.dialog.texts.history.activate') : _vm.$t('modules.dialog.texts.history.show')))])]],2)])}),_c('infinite-loading',{ref:"InfiniteHistoryLoading",attrs:{"spinner":"spiral","identifier":_vm.infiniteId,"force-use-infinite-wrapper":".table-infinity-scroll .v-data-table__wrapper"},on:{"infinite":_vm.getInfinityScrollEntityList}},[_c('span',{attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t('modules.dialog.texts.history.its_first'))+" ")]),_c('span',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v(" "+_vm._s(_vm.$t('modules.dialog.texts.history.no_more'))+" ")])])],2):_vm._e()]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
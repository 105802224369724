import 'vue-toastification/dist/index.css';

import CommonFunctions from './plugins/common_functions';
import DialogFunctions from './plugins/dialog_functions';
import Kwizbot from './App.vue';
import PortalVue from 'portal-vue';
import SvgFunctions from './plugins/svg';
import Toast from 'vue-toastification';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueLodash from 'vue-lodash';
import VueRouter from 'vue-router';
import VueSocketIOExt from 'vue-socket.io-extended';
import VueTheMask from 'vue-the-mask';
import Vuex from 'vuex';
import auth_store from './store/auth_store';
import cloneDeep from 'lodash/cloneDeep';
import common_store from './store/common_store';
import coreRouter from './router/router';
import custom_store from './store/custom_store';
import dialog_store from './store/dialog_store';
import bot_store from './store/bot_store';
import { extra_modules_list } from './modules/custom_modules_list';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import flatMapDeep from 'lodash/flatMapDeep';
import i18n from './lang/i18n';
import { io } from 'socket.io-client';
import kwizbot_store from './store/kwizbot_store';
import merge from 'lodash/merge';
import vuescroll from 'vuescroll';
import vuetify from './plugins/vuetify';
import VTooltip from "v-tooltip";

//import modules stores
let modules_stores = {};

//import modules stores
let modules_routers = [];

const connectModuleStore = (module) => {
  try {
    const module_store = require(`./modules/${module.component_name}/store/store.js`);

    if (module_store && module_store.default) {
      modules_stores = merge(modules_stores, module_store.default);
    }
  } catch (e) {
    //console.log('store file not found');
  }
};

const connectModuleRoute = (module) => {
  try {
    const module_router = require(`./modules/${module.component_name}/router/router.js`);

    if (module_router && module_router.default) {
      modules_routers.push(module_router.default);
    }
  } catch (e) {
    //console.log('store file not found');
  }
};

extra_modules_list
  .filter((module) => module && module.active && module.component_name)
  .forEach((module) => {
    connectModuleStore(module);
    connectModuleRoute(module);
  });

Vue.config.productionTip = false;

if (process.env.VUE_APP_DEVTOOLS === '1'){
  Vue.config.devtools = true
}

Vue.use(VueTheMask);
Vue.use(Vuex);
Vue.use(Toast, {
  transition: 'Vue-Toastification__slideBlurred',
	position: 'top-center',
  maxToasts: 1,
  newestOnTop: false,
});
Vue.use(VueLodash, {
  lodash: {
    cloneDeep, filter, orderBy, find, isObject, isString, flatMapDeep
  }
});
Vue.use(VueCookies, {
	sameSite: 'None',
	secure: true,
});
Vue.use(SvgFunctions);
Vue.use(CommonFunctions);
Vue.use(DialogFunctions);
Vue.use(PortalVue);
Vue.use(
    VueSocketIOExt,
    io(`${process.env.VUE_APP_CONSTRUCTOR_URL}`, {
      transportOptions: {
        polling: {},
      },
    })
);
Vue.use(vuescroll, {
  ops: {
    bar: {
      background: '#E0E0E0',
      keepShow: true,
      onlyShowBarOnScroll: false,
    },
  },
});
Vue.use(VTooltip);

const appStore = new Vuex.Store({
  modules: {
    auth: auth_store,
    kwizbot: merge(
      common_store,
      kwizbot_store,
      dialog_store,
      custom_store,
      bot_store,
      modules_stores
    ),
  },
});

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [...coreRouter, ...modules_routers.flat()],
});

new Vue({
  i18n,
  vuetify,
  router,
  store: appStore,
  render: (h) => h(Kwizbot),
}).$mount('#app');

export { appStore, router };

import i18n from "@/lang/i18n";
import Vue from "vue";
import axios from "axios";
import _ from "lodash";
import HELPERS from "@/modules/OperatorPanel/helpers";

const state = {
  broadcast_users: {
    filters: [],
    filters_schema_json: {
      INTEGER: {
        type: 'INTEGER',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal//равно
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal//не равно

          {text: i18n.t("modules.customdata.filters.filters_json.gt"), value: 'gt'},//Greater than//больше
          {text: i18n.t("modules.customdata.filters.filters_json.lt"), value: 'lt'},//Less than//меньше
          {text: i18n.t("modules.customdata.filters.filters_json.gte"), value: 'gte'},//Greater than or equal//больше или равно
          {text: i18n.t("modules.customdata.filters.filters_json.lte"), value: 'lte'},//Less than or equal//меньше или равно

          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY//пустое значение
          // {text:'like', value: 'like'},//Like search (%value, value%, %value%)//содержит
          // {text:'notLike', value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          {text: i18n.t("modules.customdata.filters.filters_json.between"), value: 'between'},//Between [value1, value2]//в диапазоне
          {text: i18n.t("modules.customdata.filters.filters_json.not_between"), value: 'notBetween'}//Not Between [value1, value2]//не в диапазоне
        ]
      },
      FLOAT: {
        type: 'FLOAT',
        conditions: [
          // {text:'=', value: 'eq'},//Equal
          // {text:'!=', value: 'ne'},//Not equal

          {text: i18n.t("modules.customdata.filters.filters_json.gt"), value: 'gt'},//Greater than
          {text: i18n.t("modules.customdata.filters.filters_json.lt"), value: 'lt'},//Less than
          {text: i18n.t("modules.customdata.filters.filters_json.gte"), value: 'gte'},//Greater than or equal
          {text: i18n.t("modules.customdata.filters.filters_json.lte"), value: 'lte'},//Less than or equal

          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          // {text:'like', value: 'like'},//Like search (%value, value%, %value%)
          // {text:'notLike', value: 'notLike'},//Not like search (%value, value%, %value%)

          {text: i18n.t("modules.customdata.filters.filters_json.between"), value: 'between'},//Between [value1, value2]
          {text: i18n.t("modules.customdata.filters.filters_json.not_between"), value: 'notBetween'}//Not Between [value1, value2]
        ]
      },

      STRING: {
        type: 'STRING',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal


          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          {text: i18n.t("modules.customdata.filters.filters_json.starts_with"), value: 'startsWith'},//Like search (%value,)//начинается с
          {text: i18n.t("modules.customdata.filters.filters_json.ends_with"), value: 'endsWith'},//Like search (value%,)//заканчивается на

          {text: i18n.t("modules.customdata.filters.filters_json.like"), value: 'like'},//Like search (%value%)//содержит
          {text: i18n.t("modules.customdata.filters.filters_json.not_like"), value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          // {text: i18n.t("modules.customdata.filters.filters_json.multiple"), value: 'multiple'},//multiple select from values//из списка

        ]
      },

      TEXT: {
        type: 'TEXT',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal


          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          {text: i18n.t("modules.customdata.filters.filters_json.starts_with"), value: 'startsWith'},//Like search (%value,)//начинается с
          {text: i18n.t("modules.customdata.filters.filters_json.ends_with"), value: 'endsWith'},//Like search (value%,)//заканчивается на

          {text: i18n.t("modules.customdata.filters.filters_json.like"), value: 'like'},//Like search (%value%)//содержит
          {text: i18n.t("modules.customdata.filters.filters_json.not_like"), value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          // {text: i18n.t("modules.customdata.filters.filters_json.multiple"), value: 'multiple'},//multiple select from values//из списка

        ]
      },

      BOOLEAN: {
        type: 'BOOLEAN',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.active"), value: 'active'},//Equal//да
          {text: i18n.t("modules.customdata.filters.filters_json.not_active"), value: 'notActive'},//Not equal//нет

          // {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY//пусто
        ]
      },
      SELECT: {
        type: 'SELECT',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.multiple"), value: 'multiple'},//multiple select from values//из списка
        ],
      },
    },

    selected_filters: {},
    saved_filters: [],
    empty_selected_filters: {},
    linkedTablesValues: {},

    toggle_active_filter: null,

    columns_schema_json: {
      name: '',
      active: true,
      position: 0,
      sort: null,
    },

    columns_data: [],
    columns_data_activated: [],
    saved_columns_data: [],
    default_columns_data: [],
  }
};

const mutations = {
  BROADCAST_USERS_SELECTED_FILTERS_EMPTY(state) {
    state.broadcast_users.selected_filters = {}
  },
  BROADCAST_USERS_SET_SAVED_FILTERS(state, filters) {
    state.broadcast_users.saved_filters = filters;
  },
  BROADCAST_USERS_SET_SAVED_COLUMNS_DATA(state, payload) {
    Vue.set(state.broadcast_users, 'saved_columns_data', _.cloneDeep(payload))
  },
  BROADCAST_USERS_SET_SELECTED_FILTER(state, {key, values}){
    Vue.set(state.broadcast_users.selected_filters, key, values)
  },
  BROADCAST_USERS_SET_FILTERS(state, filters) {
    state.broadcast_users.filters = filters;
  },
  BROADCAST_USERS_SET_FILTERS_EMPTY(state) {
    state.broadcast_users.filters = []
  },

  BROADCAST_USERS_SET_LINKED_TABLE_VALUES(state, {column, values}) {
    Vue.set(state.broadcast_users.linkedTablesValues, column, values)
  },
  BROADCAST_USERS_SET_EMPTY_SELECTED_FILTER(state, {key, values}){
    Vue.set(state.broadcast_users.empty_selected_filters, key, values)
    Vue.set(state.broadcast_users.selected_filters, key, values)
  },
  BROADCAST_USERS_SET_SELECTED_FILTER_VALUE(state, {key, value}){
    Vue.set(state.broadcast_users.selected_filters[key], 'value', value)
  },
  BROADCAST_USERS_SET_SELECTED_FILTER_CONDITION(state, {key, condition}){
    Vue.set(state.broadcast_users.selected_filters[key], 'condition', condition)
  },

  BROADCAST_USERS_DELETE_SELECTED_FILTER(state, key){
    Vue.delete(state.broadcast_users.selected_filters, key)
  },
  BROADCAST_USERS_DELETE_SELECTED_FILTER_IN_ARRAY(state, {key, value}) {
    let keyValueArray = state.broadcast_users.selected_filters[key]?.value

    if (Array.isArray(keyValueArray) && keyValueArray.length) {
      if (
        state.broadcast_users.selected_filters[key]?.condition?.value === 'between'
        || state.broadcast_users.selected_filters[key]?.condition?.value === 'notBetween'
        || state.broadcast_users.selected_filters[key]?.type === 'DATE'
      ) {
        Vue.set(state.broadcast_users.selected_filters[key], 'condition', null)
        Vue.set(state.broadcast_users.selected_filters[key], 'value', null)
      } else {
        let filteredArray = keyValueArray.filter(i => i !== value)
        if (!filteredArray.length) filteredArray = null
        Vue.set(state.broadcast_users.selected_filters[key], 'value', filteredArray)
        if (keyValueArray.length <= 1) {
          Vue.set(state.broadcast_users.selected_filters[key], 'condition', null)
        }
      }
    } else {
      Vue.set(state.broadcast_users.selected_filters[key], 'condition', null)
      Vue.set(state.broadcast_users.selected_filters[key], 'value', null)
    }
  },
  BROADCAST_USERS_SELECTED_FILTERS_CLEAR_VALUES(state) {
		Object.keys(state.broadcast_users.selected_filters)
			.forEach(function(key) {
				Vue.set(state.broadcast_users.selected_filters[key], 'condition', null)
				Vue.set(state.broadcast_users.selected_filters[key], 'value', null)
			});
  },
  BROADCAST_USERS_PUSH_SAVED_FILTERS(state, filter) {
    let filterExist = state.broadcast_users.saved_filters.find(item => item.id === filter.id)
    if (!filterExist) {
      state.broadcast_users.saved_filters.push(filter);
    } else {
      for (let i in state.broadcast_users.saved_filters) {
        if (state.broadcast_users.saved_filters[i].id === filter.id) {
          Vue.set(state.broadcast_users.saved_filters[i], 'filters_json', filter.filters_json)
        }
      }
    }
  },
  BROADCAST_USERS_DELETE_SAVED_FILTERS(state, name) {
    state.broadcast_users.saved_filters = state.broadcast_users.saved_filters.filter(item => item.name !== name);
  },
  BROADCAST_USERS_SET_COLUMNS_DATA(state, payload) {
    state.broadcast_users.columns_data = payload
  },
  BROADCAST_USERS_SET_COLUMNS_DATA_ACTIVATED(state, payload) {
    state.broadcast_users.columns_data_activated = payload
  },
  BROADCAST_USERS_TOGGLE_COLUMN_ACTIVE(state, {name, value}) {
    for (let i in state.broadcast_users.columns_data) {
      if (state.broadcast_users.columns_data[i].name === name) {
        Vue.set(state.broadcast_users.columns_data[i], 'active', value)
      }
    }
    state.broadcast_users.columns_data_activated = state.broadcast_users.columns_data
  },
  BROADCAST_USERS_CHANGE_ORDER_COLUMN(state, data) {
    const {oldIndex, newIndex} = data
    state.broadcast_users.columns_data
      .splice(newIndex, 0, state.broadcast_users.columns_data.splice(oldIndex, 1)[0])

    for (let i in state.broadcast_users.columns_data) {
      Vue.set(state.broadcast_users.columns_data[i], 'position', Number(i))
    }

    state.broadcast_users.columns_data_activated = state.broadcast_users.columns_data
  },
  BROADCAST_USERS_SET_DEFAULT_COLUMNS_DATA(state, payload) {
    state.broadcast_users.default_columns_data = payload
  },
  BROADCAST_USERS_SET_TOGGLE_ACTIVE_FILTER(state, value) {
    state.broadcast_users.toggle_active_filter = value
  },
};

const actions = {
  /**
   * @name broadcastUsersAxiosGetFilters
   * @description get list for Filters
   * @param commit
   * @param dispatch
   * @param state
   * @returns {Promise<void>}
   */
  async broadcastUsersAxiosGetFilters({ commit, dispatch, state }, data) {
		const {module_name, botId} = data
    commit('BROADCAST_USERS_SET_FILTERS_EMPTY')
    commit('BROADCAST_USERS_SELECTED_FILTERS_EMPTY')

    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const bot_id = botId || state.bot_settings.active_id;
    const url = `${engine_url}/kw/${module_name}/customers_filters`;
    const user_id = state.user.id;
    const params = {
      bot_id: bot_id,
      user_id: user_id,
    }

    dispatch('updateAjaxDialog', [true, true]);

    await axios({
      method: 'GET',
      url: url,
      headers: {
        'X-CSRF-TOKEN': token,
      },
      params,
    })
      .then( (response) => {
          dispatch('updateAjaxDialog', [false, false]);

          if ( response?.data?.status === 'success' ) {
            // const filters = response?.data?.data?.columns_json
            const filters = response?.data?.filters

            //set saved filters in state
            const savedFilters = response?.data?.users_filters
            commit('BROADCAST_USERS_SET_SAVED_FILTERS', savedFilters)

            //set columns from columns_chooser
            const columns_chooser = response?.data?.columns_chooser
            if (columns_chooser?.length) {
              dispatch('_broadcastUsersSetSavedColumnsData', columns_chooser)
            } else {
              dispatch('_broadcastUsersSetSavedColumnsData', [])
            }

            let arr = []

            for (const f in filters) {
              let obj = filters[f]
              obj['name'] =  f
              obj['label'] =  filters[f]?.field_name || null

              // obj['is_association'] = !!filters[f]?.association
              obj['values'] = filters[f]?.values || null
              obj['conditions'] = state.broadcast_users.filters_schema_json[filters[f].type]?.conditions

              dispatch('broadcastUsersSetSelectedFilter', {
                key: f,
                values: {
                  value: null,
                  condition: null,
                  type: filters[f].type,
                  // is_association: !!filters[f]?.association,
                  label: filters[f]?.field_name || filters[f]?.name || null,
                }
              })

              arr.push(obj)

              //for Linked Tables by model_name
              // if (filters[f].association) {
              //   const modelName = filters[f].association.model_name
              //   const modelField = filters[f].association?.model_field
              //   dispatch('_broadcastUsersAxiosGetLinkedTablesValues', {
              //     modelName: modelName,
              //     modelField: modelField,
              //     linkedColumn: f,
              //   })
              // }
            }

            commit('BROADCAST_USERS_SET_FILTERS', arr)
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
        }
      );
  },
  async _broadcastUsersSetSavedColumnsData({commit}, payload) {
    await commit('BROADCAST_USERS_SET_SAVED_COLUMNS_DATA', payload)
  },
  async broadcastUsersAxiosSaveFilters({ state, commit, dispatch }, { nameModule, nameFilter, filters_json, with_columns }) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const user_id = state.user.id;
    const url = `${engine_url}/kw/custom_modules/create_update_filter`

    let request = {
      user_id,
      name: nameFilter,
      filters_json,
    }

    if (with_columns) {
      request['columns_chooser'] = state.broadcast_users.columns_data
    }

    dispatch('updateAjaxDialog', [true, true]);
    await axios.post(url, request, {
      headers: {
        "X-CSRF-TOKEN": token
      },
    })
      .then( async (response) => {
          //dispatch('updateAjaxDialog', [false, false]);
          if ( response?.data?.status === 'success' ) {
            commit('BROADCAST_USERS_PUSH_SAVED_FILTERS', response.data.data)
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.filters.filter_created') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },
  async broadcastUsersAxiosDeleteFilter({ state, commit, dispatch }, { nameModule, nameFilter }) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const user_id = state.user.id;

    const url = `${engine_url}/kw/custom_modules/delete_filter`

    let request = {
      name: nameFilter,
      user_id,
    }
    dispatch('updateAjaxDialog', [true, true]);
    await axios.delete(url, {
      headers: {
        "X-CSRF-TOKEN": token
      },
      data:request,
    })
      .then( async (response) => {
          if ( response?.data?.status === 'success' ) {
            commit('BROADCAST_USERS_DELETE_SAVED_FILTERS', nameFilter)
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.filters.filter_deleted') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },
  broadcastUsersActivateSavedFilter({ state, commit, dispatch } , data) {
    //clear data values and condition in Filters List (set null)
    for (let f in state.broadcast_users.selected_filters) {
      commit('BROADCAST_USERS_SET_SELECTED_FILTER_VALUE', {key: f, value: null})
      commit('BROADCAST_USERS_SET_SELECTED_FILTER_CONDITION', {key: f, condition: null})
    }

    if (data?.filters_json) {
      for(let item of data.filters_json) {
        let name = item[0]
        let value = item[1].value
        let condition = item[1].condition

        commit('BROADCAST_USERS_SET_SELECTED_FILTER_VALUE', {key: name, value: value})
        commit('BROADCAST_USERS_SET_SELECTED_FILTER_CONDITION', {key: name, condition: condition})
      }
    }

    //set columns from saved filter or set saved from model
    if (data?.columns_chooser) {
      commit('BROADCAST_USERS_SET_COLUMNS_DATA', data.columns_chooser)
    } else {
      let columns;
      (state.broadcast_users.saved_columns_data?.length)
        ? columns = state.broadcast_users.saved_columns_data
        : columns = state.broadcast_users.default_columns_data

      commit('BROADCAST_USERS_SET_COLUMNS_DATA', _.cloneDeep(columns) )
    }
  },
  broadcastUsersSetEmptySelectedFilter({ commit }, {key, values}) {
    commit('BROADCAST_USERS_SET_EMPTY_SELECTED_FILTER', {key, values})
  },
  broadcastUsersSetSelectedFilter({ commit }, {key, values}) {
    commit('BROADCAST_USERS_SET_SELECTED_FILTER', {key, values})
  },
  broadcastUsersSetSelectedFilterValue({ commit }, {key, value}) {
    commit('BROADCAST_USERS_SET_SELECTED_FILTER_VALUE', {key, value})
  },
  broadcastUsersSetSelectedFilterCondition({ commit }, {key, condition}) {
    commit('BROADCAST_USERS_SET_SELECTED_FILTER_CONDITION', {key, condition})
  },

  broadcastUsersDeleteSelectedFilter({ commit }, key) {
    commit('BROADCAST_USERS_DELETE_SELECTED_FILTER', key)
  },
  broadcastUsersDeleteSelectedFilterInArray({ commit }, {key, value}) {
    commit('BROADCAST_USERS_DELETE_SELECTED_FILTER_IN_ARRAY', {key, value})
  },
  broadcastUsersSetSelectedFiltersEmpty({ commit }) {
    commit('BROADCAST_USERS_SELECTED_FILTERS_CLEAR_VALUES')
  },

  broadcastUsersSetColumnsData({ commit }, data) {
    let arr = []
    if (data.length && data[0].hasOwnProperty('position')) {
      arr = data
    } else if(data.length) {
      for (let i in data) {
        let item = {
          ...data[i],
          active: true,
          position: Number(i),
          sort: null,
        }

        arr.push(item)
      }
    }

    commit('BROADCAST_USERS_SET_COLUMNS_DATA', arr)
  },
  broadcastUsersSetDefaultColumnsData({ commit }, data) {
    let arr = []
    for (let i in data) {
      let item = {
        ...data[i],
        active: true,
        position: Number(i),
        sort: null,
      }

      arr.push(item)
    }
    commit('BROADCAST_USERS_SET_DEFAULT_COLUMNS_DATA', arr)
  },
  broadcastUsersSetColumnsDataFromActivated({ getters, commit }, mode) {
    if (mode) {
      commit('BROADCAST_USERS_SET_COLUMNS_DATA', [])
      commit('BROADCAST_USERS_SET_COLUMNS_DATA_ACTIVATED', [])
    } else {
      commit('BROADCAST_USERS_SET_COLUMNS_DATA', getters.broadcast_users_columns_data_activated)
    }
  },

  broadcastUsersSetToggleActiveFilter({ dispatch, commit }, value) {
    commit('BROADCAST_USERS_SET_TOGGLE_ACTIVE_FILTER', value)
    dispatch('broadcastUsersActivateSavedFilter', value)
  },

  //Columns ACTIONS
  broadcastUsersToggleColumnActive({ commit }, {name, value}) {
    commit('BROADCAST_USERS_TOGGLE_COLUMN_ACTIVE', {name, value})
  },
  broadcastUsersChangeOrderColumn({ state, commit }, data) {
    commit('BROADCAST_USERS_CHANGE_ORDER_COLUMN', data)
  },
  async broadcastUsersAxiosSaveColumnsData({ state, dispatch, commit, }, {name, reset}) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const bot_id = state.bot_settings.active_id;
    const columns_chooser =
      reset ? {} : state.broadcast_users.columns_data_activated

    const url = `${engine_url}/kw/custom_modules/create_update_filter`

    let request = {
      // bot_id,
      user_id: state.user.id,
      name,
      columns_chooser,
    }
    dispatch('updateAjaxDialog', [true, true]);

    await axios.post(url, request, {
      headers: {
        "X-CSRF-TOKEN": token
      },
    })
      .then( async (response) => {
          dispatch('updateAjaxDialog', [false, false]);
          if ( response?.data?.status === 'success' ) {
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.columns_chooser.columns_saved_success') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },
  broadcastUsersResetColumnsDataToDefault({ state, dispatch }) {
    dispatch('broadcastUsersAxiosSaveColumnsData', {name: null, reset: true})
    Vue.set(state.broadcast_users, 'columns_data', state.broadcast_users.default_columns_data)
  },

  broadcastUsersResetColumnsDataForFilterToSaved({ state, commit }) {
    let columns;

    (state.broadcast_users.saved_columns_data?.length)
      ? columns = _.cloneDeep(state.broadcast_users.saved_columns_data)
      : columns = _.cloneDeep(state.broadcast_users.default_columns_data)

    commit('BROADCAST_USERS_SET_COLUMNS_DATA', columns)
  },
  //end Columns ACTIONS

};

const getters = {
  broadcast_users_filters: (state) => state.broadcast_users.filters,
  broadcast_users_linked_tables_values: (state) => state.broadcast_users.linkedTablesValues,
  broadcast_users_selected_filters: (state) => state.broadcast_users.selected_filters,
  broadcast_users_saved_filters: (state) => state.broadcast_users.saved_filters.filter((f) => !!f?.name),

  broadcast_users_toggle_active_filter: (state) => state.broadcast_users.toggle_active_filter,

  broadcast_users_columns_data: (state) => state.broadcast_users.columns_data,
  broadcast_users_columns_data_activated: (state) => state.broadcast_users.columns_data_activated,
  broadcast_users_saved_columns_data: (state) => state.broadcast_users.saved_columns_data,
  broadcast_users_default_columns_data: (state) => state.broadcast_users.default_columns_data,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
<template>
  <div v-if="dialog.params.current_section_id">
    <router-view></router-view>

    <Lang/>

    <v-layout
      v-if="user.role.permissions.scenario.sections.scenario.canSave"
      id="save-button"
      class="elevation-23 rounded overflow-hidden"

      :class="[
        checkRouteIsHistory ? 'warning' : 'primary',
        disableSave ? 'gray': null,
      ]"
      :align-center="true"
    >
      <v-btn
         elevation="0"
         :loading="false"
         @click="
         checkRouteIsHistory
          ? activateHistoryModal = true
          : axiosSaveBot()
         "
         :fab="$vuetify.breakpoint.smAndDown"
         :disabled="disableSave"
         text
         class="text-none"
         color="white"
      >
        <v-icon
          small
          :class="{ 'mr-2': $vuetify.breakpoint.mdAndUp }"
          v-text="checkRouteIsHistory ? 'mdi-alert' : '$iconsave'"
        />
        <span v-if="$vuetify.breakpoint.mdAndUp">
          {{
            checkRouteIsHistory
              ? $t('modules.dialog.texts.history.activate')
              : $t('modules.dialog.texts.settings.save')
          }}
        </span>
      </v-btn>

      <v-divider
        v-if="getScenarioHistory.length > 0"
        vertical />

      <v-btn
        v-if="getScenarioHistory.length > 0"
        text
        class="pa-0"
        color="white"
        min-width="40"
        @click="showHistory = true"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>

    </v-layout>

    <v-snackbar
      :value="disableSave"
      color="red"
      tile
      :timeout="-1"
      height="auto"
    >
      {{$t('modules.dialog.texts.interface.disable_save.text_alert')}} <b>{{scenarioOwnerUserEmail}}</b>
      <template v-slot:action="{ attrs }">
        <v-btn
          elevation="0"
          small
          color="primary"
          v-bind="attrs"
          @click="resetScenarioUser()"
        >
          {{$t('modules.dialog.texts.interface.disable_save.remove_user')}}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      :value="reloadAfterEditingEnded"
      text
      color="red"
      tile
      :timeout="-1"
      height="auto"
    >
      {{$t('modules.dialog.texts.interface.disable_save.text_warning')}}
      <template v-slot:action="{ attrs }">
        <v-btn
          elevation="0"
          small
          color="primary"
          v-bind="attrs"
          @click="reloadPage()"
        >
          {{$t('modules.dialog.texts.interface.disable_save.reload_page')}}
        </v-btn>
      </template>
    </v-snackbar>

    <ModalScenarioHistory
      v-if="showHistory"
      v-model="showHistory"
    />

    <v-dialog
      v-model="activateHistoryModal"
      width="550"
      scrollable
    >
      <v-card>
        <v-card-title>
          {{$t('modules.dialog.texts.history.confirm_title')}}
          <v-btn
            absolute
            style="top: 5px; right: 5px;"
            @click="activateHistoryModal = false"
            icon
            :ripple="false"
            depressed
            small
            class="ml-2"
          ><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert
            type="info"
            text
            class="mb-3"
          >
            {{$t('modules.dialog.texts.history.confirm_text')}}
          </v-alert>
          <v-checkbox
            hide-details
            v-model="keep_current_version"
            :label="$t('modules.dialog.texts.history.confirm_keep_current')"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="warning"
            class="text-none"
            elevation="0"
            @click="axiosSaveBot({
              keep_current: keep_current_version
            })"
          >
            {{$t('common.confirm')}}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Lang from './common/Lang';
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuid_v4 } from 'uuid';
import i18n from "@/lang/i18n";
// import Vue from 'vue';
// import axios from 'axios/index';
import ModalScenarioHistory from "@/views/Dialog/ModalScenarioHistory.vue";

export default {
  name: 'Dialog',

  components: {
    Lang,
    ModalScenarioHistory,
  },

  data: function () {
    return {
      overlay: true,

      bot_id: null,
      disableSave: false,
      scenarioOwnerSocketId: null,
      scenarioOwnerUserEmail: null,
      reloadAfterEditingEnded: false,
      showHistory: false,
      activateHistoryModal: false,
      keep_current_version: false,
    }
  },

  computed: {
    ...mapGetters([
      'dialog',
      'engine_settings',
      // 'bot_settings',
      // 'current_tab_type',
      'all_bot_data_init',
      'user',
      'getScenarioHistory',
    ]),

    checkRouteIsHistory() {
      return this.$route.name === 'bot_scenario_history'
        && this.$route.params?.scenario_id
    },
  },

  mounted: async function () {
    this.clearDialogNodes()

    await this.$store.dispatch('loadData');

    // if (this.all_bot_data_init) {
    //   this.$store.dispatch('loadDialogData');
    // }

    await this.loadBotSettings()

    //Сохранение по нажатию ctrl + s
    if (this.dialog.params.save_listener_installed === false) {
      this.$store.commit('SET_DIALOG_PARAMS', {
        field: 'save_listener_installed',
        value: true,
      });
      window.addEventListener('keydown', function (event) {
        if (event.ctrlKey && event.code) {
          if (event.code === 'KeyS') {
            document.getElementById('save-button').click();
            event.preventDefault();
            return false;
          }
        }
      });
    }

    /*** Disabling Save Btn for "others" mounted users ***/
    this.bot_id = this.$route.params?.id
    let $this = this

    this.$socket.client.on('someone_mounted_on_scenario', (data) => {
      const { socket_id, email } = data[$this.$route.params?.id]

      if(data?.[this.bot_id]) {
        $this.scenarioOwnerSocketId = socket_id
        $this.scenarioOwnerUserEmail = email

        if(socket_id && socket_id !== $this.$socket.client.id) {
          $this.reloadAfterEditingEnded = false
          $this.disableSave = true
        } else if(!email && $this.disableSave){
          $this.disableSave = true
          $this.reloadAfterEditingEnded = true
        }
      }
    })

    this.$socket.client.emit('on_mount_scenario', {
      bot_id: this.bot_id,
      email: this.user?.email,
    });
    /*** end Disabling Save Btn for "others" mounted users ***/
  },

  beforeDestroy() {
    //Disabling Save Btn for "others" mounted users
    if(this.scenarioOwnerSocketId === this.$socket.client.id) {
      this.$socket.client.emit('on_reset_scenario_user', {
        bot_id: this.bot_id,
        with_emit: true,
      })
    }
    this.$socket.client.off('someone_mounted_on_scenario')
  },

  methods: {
    ...mapActions([
      // 'setActiveBot',
      'axiosSaveBot',

      'axiosGetBotSettingsById',
      'clearDialogNodes',
    ]),

    // save() {
    //   let url = this.engine_settings.active_url;
    //   let bot_id = parseInt(this.bot_settings.active_id);
    //   let request = this._.cloneDeep(this.dialog);
    //   request.bot_id = bot_id;
    //   request.updatedAt = this.bot_settings.updatedAt;
    //   let token = Vue.$cookies.get('token_e');
    //
    //   if (url && bot_id && token) {
    //     this.$store.dispatch('updateAjaxDialog', [true, true]);
    //     axios
    //       .post(url + '/kw/dialog/save/', request, {
    //         headers: {
    //           'X-CSRF-TOKEN': token,
    //         },
    //       })
    //       .then(
    //         (response) => {
    //           this.$store.dispatch('updateAjaxDialog', [false, false]);
    //           if (
    //             response.data?.status === 'success' &&
    //             response.data.data?.updatedAt
    //           ) {
    //             this.$store.commit('SET_BOT_SETTING', {
    //               field: 'updatedAt',
    //               value: parseInt(response.data.data.updatedAt),
    //             });
    //           } else {
    //             this.$store.dispatch('updateAjaxDialog', [
    //               true,
    //               false,
    //               this.$t('modules.dialog.texts.common.warning'),
    //               this.$t('modules.dialog.texts.common.wrong_response_status'),
    //             ]);
    //           }
    //         },
    //         (err) => {
    //           let title = this.$t('common.error');
    //           let description = this.$t(
    //             'modules.dialog.texts.common.cant_save_dialog'
    //           );
    //           if (err.response.data.message === 'bot_last_update_error') {
    //             title = this.$t('modules.dialog.texts.common.cant_save_dialog');
    //             description = this.$t(
    //               'modules.dialog.texts.common.another_user_save_dialog'
    //             );
    //           }
    //
    //           this.$store.dispatch('updateAjaxDialogError', [
    //             err,
    //             title,
    //             description,
    //           ]);
    //         }
    //       );
    //   }
    // },

    async loadBotSettings () {
      let $this = this

      await this.axiosGetBotSettingsById(this.$route.params?.id).then(async function() {
        await $this.$store.dispatch('loadDialogData');
        await $this.$store.dispatch('axiosGetBotScenarioHistory');
      })
    },

    async resetScenarioUser() {
      if (
        confirm(`${i18n.t('modules.dialog.texts.interface.disable_save.remove_confirm_1')} ${ this.scenarioOwnerUserEmail} ${i18n.t('modules.dialog.texts.interface.disable_save.remove_confirm_2')}`)
      ) {
        this.$socket.client.emit('on_reset_scenario_user', {
          bot_id: this.bot_id,
          with_emit: false,
        })
        this.disableSave = false

        this.$socket.client.emit('on_mount_scenario', {
          bot_id: this.bot_id,
          email: this.user?.email,
        });

        this.reloadPage()
      }
    },

    reloadPage() {
      location.reload()
    },
  },

  watch: {
    //Изменение языка
    // all_bot_data_init: {
    //   handler: function () {
    //     if (this.all_bot_data_init && this.all_bot_data_init.ready) {
    //       this.$store.dispatch('loadDialogData');
    //     }
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style scoped lang="scss">
#save-button {
  position: fixed;
  bottom: 16px;
  left: 80px;
  //min-width: 190px;

  @media screen and (max-width: 959px) {
    min-width: auto;
    max-height: 40px;

    left: 15px;
  }
}
</style>

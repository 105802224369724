<template>
  <v-progress-circular
    v-if="getter_ajax_dialog.show && getter_ajax_dialog.loading"
    indeterminate
    color="primary"
    class="preloader-progress"
  ></v-progress-circular>
</template>

<script>
import { mapGetters } from 'vuex';

export default{
    name: "AxiosPreloaderAndResponse",
    props: [],

  data: () => ({
    toastOptions: {
      position: 'top-center',
      timeout: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      closeButton: 'button',
      icon: true,
    },
  }),

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if(
        mutation.type === 'UPDATE_AJAX_DIALOG'
        && this.getter_ajax_dialog?.show
        && !this.getter_ajax_dialog?.loading
      ) this.prepareToastNotification()
    });
  },

  destroyed() {
    this.unsubscribe()
  },

  computed: {
    ...mapGetters({
      getter_ajax_dialog: 'ajax_dialog',
      notifications: 'getNotifications',
      opUserSettingsJson: 'opUserSettingsJson',
    }),
  },

  methods: {
    prepareToastNotification() {
      const val = this.getter_ajax_dialog

      //Preparing Content
      let myJSX;
      if(val?.title) {
        myJSX = (
          <div>
            <h4><b>{val?.title}</b></h4>
            <div>{val?.description}</div>
          </div>
        );
      } else {
        myJSX = ( <div>{val?.description}</div> );
      }
      //end Preparing Content

      let show = true
      const sys_notif_settings = this.opUserSettingsJson.system_notifications

      switch (val?.description) {
        case this.$t('modules.op.texts.new_dialog'):
          show = sys_notif_settings.chat_room_created
          break;
        case this.$t('modules.op.texts.new_message'):
          show = sys_notif_settings.new_message
          break;
        case this.$t('modules.op.texts.participant_added'):
          show = sys_notif_settings.participants_connected
          break;
        case this.$t('modules.op.texts.chat_closed'):
          show = sys_notif_settings.chat_room_closed
          break;
        case this.$t('modules.op.texts.chat_closed_by_client'):
          show = sys_notif_settings.chat_room_closed
          break;
        case this.$t('modules.op.texts.chat_closed_by_timeout'):
          show = sys_notif_settings.chat_room_closed
          break;

        default:
          break;
      }

      if(show)
        this._showToast(myJSX);
    },

    _showToast(myJSX) {
      let toastOptions = JSON.parse(JSON.stringify(this.toastOptions))

      this.$toast.clear()
      const val = this.getter_ajax_dialog

      if(val?.error && val?.description) {
        toastOptions['type'] = 'error'
      }

      const notificationsErrorArr = this.notifications.filter(el => el.type === 'error')
      if(notificationsErrorArr?.length) {
        //Don't show if previous is duplicated by type and text
        let lastError = notificationsErrorArr[notificationsErrorArr.length - 1]
        if(
          lastError?.text === val.description
          && lastError?.title?.indexOf('!') === -1 //exception if title with symbol '!'
        ) {
          if(this.checkNotificationDifferenceMinutes(
            new Date().toISOString(),
            notificationsErrorArr[notificationsErrorArr.length - 1].date
          )) {
            this.$toast(myJSX, { ...toastOptions, ...val?.options });
          }
          notificationsErrorArr[notificationsErrorArr.length - 1].date = new Date().toISOString()
          notificationsErrorArr[notificationsErrorArr.length - 1].read = false

          localStorage.setItem(
            'kw_notifications',
            JSON.stringify(this.notifications),
          )

          return
        }
        //end Don't show if previous is duplicated by type and text
      }

      const notificationsInfoArr = this.notifications.filter(el => el.type !== 'error')
      if(notificationsInfoArr?.length) {
        //Don't show if previous is duplicated by type and text
        let lastInfo = notificationsInfoArr[notificationsInfoArr.length - 1]

        if(val?.description && lastInfo?.text === val?.description) {
          if(this.checkNotificationDifferenceMinutes(
            new Date().toISOString(),
            notificationsInfoArr[notificationsInfoArr.length - 1].date,
            1
          )) {
            this.$toast(myJSX, { ...toastOptions, ...val?.options });
          }
        } else {
          this.$toast(myJSX, { ...toastOptions, ...val?.options });
        }
      } else {
        this.$toast(myJSX, { ...toastOptions, ...val?.options });
      }

      //add notification in list
      this.$store.dispatch('setNotification', {
        type: val?.error ? 'error' : 'info',
        title: val?.title,
        text: val.description,
        date: new Date().toISOString(),
        read: false,
        message_type: val?.message_type,
        message_data: val?.message_data,
      })

      //Save notifications to storage
      localStorage.setItem(
        'kw_notifications',
        JSON.stringify(this.notifications),
      )
      //end Save notifications to storage
    },

    checkNotificationDifferenceMinutes(date_1, date_2, minutes = 15) {
      const date1 = new Date(date_1);
      const date2 = new Date(date_2);
      const differenceInMilliseconds = Math.abs(date1 - date2); // Get the absolute difference in milliseconds
      const differenceInMinutes = differenceInMilliseconds / (1000 * 60); // Convert milliseconds to minutes

      return differenceInMinutes > minutes;
    },
  },
}
</script>

<style scoped lang="scss">
  .preloader-progress {
    position: absolute;
    bottom: 50px;
    left: 50%;
    right: 0;
    z-index: 10000;
    transform: translate(-50%, 0);

  }
    .text-center{
        text-align: center !important;
    }
</style>
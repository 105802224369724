import i18n from "@/lang/i18n";
import Vue from "vue";
import axios from "axios";
import _ from "lodash";
import { router } from "@/main";

const state = {
  fast_line:{
    tabs: [
      {
        name: i18n.t('modules.fast_line.tabs.import'),
        value: 'import',
        disabled: false,
      },
      {
        name: i18n.t('modules.fast_line.tabs.questions'),
        value: 'questions',
        disabled: true,
      },
      {
        name: i18n.t('modules.fast_line.tabs.variants'),
        value: 'variations',
        disabled: true,
      },
      {
        name: i18n.t('modules.fast_line.tabs.statistics'),
        value: 'statistics',
        disabled: true,
      },
    ],

    filters: [],
    filters_schema_json: {
      INTEGER: {
        type: 'INTEGER',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal//равно
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal//не равно

          {text: i18n.t("modules.customdata.filters.filters_json.gt"), value: 'gt'},//Greater than//больше
          {text: i18n.t("modules.customdata.filters.filters_json.lt"), value: 'lt'},//Less than//меньше
          {text: i18n.t("modules.customdata.filters.filters_json.gte"), value: 'gte'},//Greater than or equal//больше или равно
          {text: i18n.t("modules.customdata.filters.filters_json.lte"), value: 'lte'},//Less than or equal//меньше или равно

          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY//пустое значение
          // {text:'like', value: 'like'},//Like search (%value, value%, %value%)//содержит
          // {text:'notLike', value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          {text: i18n.t("modules.customdata.filters.filters_json.between"), value: 'between'},//Between [value1, value2]//в диапазоне
          {text: i18n.t("modules.customdata.filters.filters_json.not_between"), value: 'notBetween'}//Not Between [value1, value2]//не в диапазоне
        ]
      },
      FLOAT: {
        type: 'FLOAT',
        conditions: [
          // {text:'=', value: 'eq'},//Equal
          // {text:'!=', value: 'ne'},//Not equal

          {text: i18n.t("modules.customdata.filters.filters_json.gt"), value: 'gt'},//Greater than
          {text: i18n.t("modules.customdata.filters.filters_json.lt"), value: 'lt'},//Less than
          {text: i18n.t("modules.customdata.filters.filters_json.gte"), value: 'gte'},//Greater than or equal
          {text: i18n.t("modules.customdata.filters.filters_json.lte"), value: 'lte'},//Less than or equal

          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          // {text:'like', value: 'like'},//Like search (%value, value%, %value%)
          // {text:'notLike', value: 'notLike'},//Not like search (%value, value%, %value%)

          {text: i18n.t("modules.customdata.filters.filters_json.between"), value: 'between'},//Between [value1, value2]
          {text: i18n.t("modules.customdata.filters.filters_json.not_between"), value: 'notBetween'}//Not Between [value1, value2]
        ]
      },

      STRING: {
        type: 'STRING',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal


          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          {text: i18n.t("modules.customdata.filters.filters_json.starts_with"), value: 'startsWith'},//Like search (%value,)//начинается с
          {text: i18n.t("modules.customdata.filters.filters_json.ends_with"), value: 'endsWith'},//Like search (value%,)//заканчивается на

          {text: i18n.t("modules.customdata.filters.filters_json.like"), value: 'like'},//Like search (%value%)//содержит
          {text: i18n.t("modules.customdata.filters.filters_json.not_like"), value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          {text: i18n.t("modules.customdata.filters.filters_json.multiple"), value: 'multiple'},//multiple select from values//из списка

        ]
      },

      TEXT: {
        type: 'TEXT',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.eq"), value: 'eq'},//Equal
          {text: i18n.t("modules.customdata.filters.filters_json.ne"), value: 'ne'},//Not equal


          {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY
          {text: i18n.t("modules.customdata.filters.filters_json.starts_with"), value: 'startsWith'},//Like search (%value,)//начинается с
          {text: i18n.t("modules.customdata.filters.filters_json.ends_with"), value: 'endsWith'},//Like search (value%,)//заканчивается на

          {text: i18n.t("modules.customdata.filters.filters_json.like"), value: 'like'},//Like search (%value%)//содержит
          {text: i18n.t("modules.customdata.filters.filters_json.not_like"), value: 'notLike'},//Not like search (%value, value%, %value%)//не содержит

          {text: i18n.t("modules.customdata.filters.filters_json.multiple"), value: 'multiple'},//multiple select from values//из списка

        ]
      },

      BOOLEAN: {
        type: 'BOOLEAN',
        conditions: [
          {text: i18n.t("modules.customdata.filters.filters_json.active"), value: 'active'},//Equal//да
          {text: i18n.t("modules.customdata.filters.filters_json.not_active"), value: 'notActive'},//Not equal//нет

          // {text: i18n.t("modules.customdata.filters.filters_json.empty"), value: 'empty'},//EMPTY//пусто
        ]
      },
    },

    selected_filters: {},
    saved_filters: [],
    empty_selected_filters: {},
    linkedTablesValues: {},

    toggle_active_filter: null,

    columns_schema_json: {
      name: '',
      active: true,
      position: 0,
      sort: null,
    },

    columns_data: [],
    columns_data_activated: [],
    saved_columns_data: [],
    default_columns_data: [],

    openai_token: null,
		widget_url: null,
		in_generation: null,
		in_education: null,

  }
};

const mutations = {
  FAST_LINE_SELECTED_FILTERS_EMPTY(state) {
    state.fast_line.selected_filters = {}
  },
  FAST_LINE_SET_SAVED_FILTERS(state, filters) {
    state.fast_line.saved_filters = filters;
  },
  FAST_LINE_SET_SAVED_COLUMNS_DATA(state, payload) {
    Vue.set(state.fast_line, 'saved_columns_data', _.cloneDeep(payload))
  },
  FAST_LINE_SET_SELECTED_FILTER(state, {key, values}){
    Vue.set(state.fast_line.selected_filters, key, values)
  },
  FAST_LINE_SET_FILTERS(state, filters) {
    state.fast_line.filters = filters;
  },
  FAST_LINE_SET_FILTERS_EMPTY(state) {
    state.fast_line.filters = []
  },

  FAST_LINE_SET_LINKED_TABLE_VALUES(state, {column, values}) {
    Vue.set(state.fast_line.linkedTablesValues, column, values)
  },
  FAST_LINE_SET_EMPTY_SELECTED_FILTER(state, {key, values}){
    Vue.set(state.fast_line.empty_selected_filters, key, values)
    Vue.set(state.fast_line.selected_filters, key, values)
  },
  FAST_LINE_SET_SELECTED_FILTER_VALUE(state, {key, value}){
    Vue.set(state.fast_line.selected_filters[key], 'value', value)
  },
  FAST_LINE_SET_SELECTED_FILTER_CONDITION(state, {key, condition}){
    Vue.set(state.fast_line.selected_filters[key], 'condition', condition)
  },

  FAST_LINE_DELETE_SELECTED_FILTER(state, key){
    Vue.delete(state.fast_line.selected_filters, key)
  },
  FAST_LINE_DELETE_SELECTED_FILTER_IN_ARRAY(state, {key, value}) {
    let keyValueArray = state.fast_line.selected_filters[key]?.value

    if (Array.isArray(keyValueArray) && keyValueArray.length) {
      if (
        state.fast_line.selected_filters[key]?.condition?.value === 'between'
        || state.fast_line.selected_filters[key]?.condition?.value === 'notBetween'
        || state.fast_line.selected_filters[key]?.type === 'DATE'
      ) {
        Vue.set(state.fast_line.selected_filters[key], 'condition', null)
        Vue.set(state.fast_line.selected_filters[key], 'value', null)
      } else {
        let filteredArray = keyValueArray.filter(i => i !== value)
        if (!filteredArray.length) filteredArray = null
        Vue.set(state.fast_line.selected_filters[key], 'value', filteredArray)
        if (keyValueArray.length <= 1) {
          Vue.set(state.fast_line.selected_filters[key], 'condition', null)
        }
      }
    } else {
      Vue.set(state.fast_line.selected_filters[key], 'condition', null)
      Vue.set(state.fast_line.selected_filters[key], 'value', null)
    }
  },
	FAST_LINE_SELECTED_FILTERS_CLEAR_VALUES(state) {
		Object.keys(state.fast_line.selected_filters)
			.forEach(function(key) {
				Vue.set(state.fast_line.selected_filters[key], 'condition', null);
				Vue.set(state.fast_line.selected_filters[key], 'value', null);
			});
	},
  FAST_LINE_PUSH_SAVED_FILTERS(state, filter) {
    let filterExist = state.fast_line.saved_filters.find(item => item.id === filter.id)
    if (!filterExist) {
      state.fast_line.saved_filters.push(filter);
    } else {
      for (let i in state.fast_line.saved_filters) {
        if (state.fast_line.saved_filters[i].id === filter.id) {
          Vue.set(state.fast_line.saved_filters[i], 'filters_json', filter.filters_json)
        }
      }
    }
  },
  FAST_LINE_DELETE_SAVED_FILTERS(state, name) {
    state.fast_line.saved_filters = state.fast_line.saved_filters.filter(item => item.name !== name);
  },
  FAST_LINE_SET_COLUMNS_DATA(state, payload) {
    state.fast_line.columns_data = payload
  },
  FAST_LINE_SET_COLUMNS_DATA_ACTIVATED(state, payload) {
    state.fast_line.columns_data_activated = payload
  },
  FAST_LINE_TOGGLE_COLUMN_ACTIVE(state, {name, value}) {
    for (let i in state.fast_line.columns_data) {
      if (state.fast_line.columns_data[i].name === name) {
        Vue.set(state.fast_line.columns_data[i], 'active', value)
      }
    }
    state.fast_line.columns_data_activated = state.fast_line.columns_data
  },
  FAST_LINE_CHANGE_ORDER_COLUMN(state, data) {
    const {oldIndex, newIndex} = data
    state.fast_line.columns_data
      .splice(newIndex, 0, state.fast_line.columns_data.splice(oldIndex, 1)[0])

    for (let i in state.fast_line.columns_data) {
      Vue.set(state.fast_line.columns_data[i], 'position', Number(i))
    }

    state.fast_line.columns_data_activated = state.fast_line.columns_data
  },
  FAST_LINE_SET_DEFAULT_COLUMNS_DATA(state, payload) {
    state.fast_line.default_columns_data = payload
  },
  FAST_LINE_SET_TOGGLE_ACTIVE_FILTER(state, value) {
    state.fast_line.toggle_active_filter = value
  },

  FAST_LINE_SET_OPENAI_TOKEN(state, value) {
    state.fast_line.openai_token = value
  },

	FAST_LINE_SET_WIDGET_URL(state, value) {
		state.fast_line.widget_url = value
	},
	FAST_LINE_SET_IN_GENERATION(state, value) {
		state.fast_line.in_generation = value
	},
	FAST_LINE_SET_IN_EDUCATION(state, value) {
		state.fast_line.in_education = value
	},
};

const actions = {
  /**
   * Clear custom data model  data
   * @method CustomDataAxiosClearModel
   * @param name - model name
   */
  async FastLineAxiosClearModel({ dispatch, state }, {name}) {

    let success = false;

    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const bot_id = router.currentRoute?.params?.id || state.bot_settings.active_id;

    const CapitalizeName = name.charAt(0).toUpperCase() + name.slice(1).slice(0, -1);

    const url = `${engine_url}/kw/fast_line/${CapitalizeName}/clear_all/?bot_id=${bot_id}`

    dispatch('updateAjaxDialog', [true, true]);

    await axios.delete(url, {
      headers: {
        "X-CSRF-TOKEN": token
      },

    })
      .then( async (response) => {
          if ( response?.data?.status === 'success' ) {
            success = true;

            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.model.cleared') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );

    return success;
  },

  async FastLineAxiosGetAvailableTabs({  dispatch, state, commit }) {
    let success = false;
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const bot_id = router.currentRoute?.params?.id || state.bot_settings.active_id;
    const url = `${engine_url}/kw/fast_line/steps`
    const params = { bot_id }

    await axios.get(url, {
      headers: {
        "X-CSRF-TOKEN": token
      },
      params
    })
      .then( async (response) => {
					// set OpenAI Token
					commit('FAST_LINE_SET_OPENAI_TOKEN', response.data.data?.openai_token)
					// set Widget Url
					commit('FAST_LINE_SET_WIDGET_URL', response.data.data?.widget_url)
					// set in_generation
					commit('FAST_LINE_SET_IN_GENERATION', response.data.data?.in_generation)
					// set in_generation
					commit('FAST_LINE_SET_IN_EDUCATION', response.data.data?.in_education)

          let indexQuestions = state.fast_line.tabs.findIndex(x => x.value === 'questions')
          let indexVariations = state.fast_line.tabs.findIndex(x => x.value === 'variations')
          let indexStatistics = state.fast_line.tabs.findIndex(x => x.value === 'statistics')

          response.data.data?.question
            ? state.fast_line.tabs[indexQuestions].disabled = false
            : state.fast_line.tabs[indexQuestions].disabled = true

          response.data.data?.variation
            ? state.fast_line.tabs[indexVariations].disabled = false
            : state.fast_line.tabs[indexVariations].disabled = true

          response.data.data?.statistics
            ? state.fast_line.tabs[indexStatistics].disabled = false
            : state.fast_line.tabs[indexStatistics].disabled = true
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
        }
      );

    return success;
  },

	async FastLineAxiosGetOpenAIToken({state, commit, dispatch}, botId = null) {
		let success = false;
		const token = Vue.$cookies.get('token_e')
		const engine_url = state.engine_settings.active_url;
		const bot_id = botId || router.currentRoute?.params?.id || state.bot_settings.active_id;
		const url = `${engine_url}/kw/fast_line/openai_token`
		const params = { bot_id }

		await axios.get(url, {
			headers: {
				"X-CSRF-TOKEN": token
			},
			params
		})
			.then( async (response) => {  
					// set OpenAI Token
					commit('FAST_LINE_SET_OPENAI_TOKEN', response.data.data?.openai_token)
				},
				(err) => {
					dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
				}
			);

		return success;
	},
  /**
   * @name FastLineAxiosGetFilters
   * @description get list for Filters
   * @param commit
   * @param dispatch
   * @param state
   * @returns {Promise<void>}
   */
  async FastLineAxiosGetFilters({ commit, dispatch, state }, module_name) {
    commit('FAST_LINE_SET_FILTERS_EMPTY')
    commit('FAST_LINE_SELECTED_FILTERS_EMPTY')

    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const bot_id = router.currentRoute?.params?.id || state.bot_settings.active_id;
    const url = `${engine_url}/kw/fast_line/filters/${module_name}`;
    const user_id = state.user.id;
    const params = {
      bot_id: bot_id,
      user_id: user_id,
    }

    dispatch('updateAjaxDialog', [true, true]);

    await axios({
      method: 'GET',
      url: url,
      headers: {
        'X-CSRF-TOKEN': token,
      },
      params,
    })
      .then( (response) => {
          dispatch('updateAjaxDialog', [false, false]);

          if ( response?.data?.status === 'success' ) {
            // const filters = response?.data?.data?.columns_json
            const filters = response?.data?.filters

            //set saved filters in state
            const savedFilters = response?.data?.users_filters
            commit('FAST_LINE_SET_SAVED_FILTERS', savedFilters)

            //set columns from columns_chooser
            const columns_chooser = response?.data?.columns_chooser
            if (columns_chooser?.length) {
              dispatch('_FastLineSetSavedColumnsData', columns_chooser)
            } else {
              dispatch('_FastLineSetSavedColumnsData', [])
            }

            let arr = []

            for (const f in filters) {
              let obj = filters[f]
              obj['name'] =  f
              obj['label'] =  filters[f]?.field_name || null

              // obj['is_association'] = !!filters[f]?.association
              obj['values'] = filters[f]?.values || null
              obj['conditions'] = state.fast_line.filters_schema_json[filters[f].type]?.conditions

              dispatch('FastLineSetSelectedFilter', {
                key: f,
                values: {
                  value: null,
                  condition: null,
                  type: filters[f].type,
                  // is_association: !!filters[f]?.association,
                  label: filters[f]?.field_name || filters[f]?.name || null,
                }
              })

              arr.push(obj)
            }

            commit('FAST_LINE_SET_FILTERS', arr)
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
        }
      );
  },
  async _FastLineSetSavedColumnsData({commit}, payload) {
    await commit('FAST_LINE_SET_SAVED_COLUMNS_DATA', payload)
  },
  async FastLineAxiosSaveFilters({ state, commit, dispatch }, { nameModule, nameFilter, filters_json, with_columns }) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const user_id = state.user.id;
    const url = `${engine_url}/kw/custom_modules/create_update_filter`

    let request = {
      user_id,
      name: nameFilter,
      filters_json,
      kw_module_name: `fast_line_${nameModule}`
    }

    if (with_columns) {
      request['columns_chooser'] = state.fast_line.columns_data
    }

    dispatch('updateAjaxDialog', [true, true]);
    await axios.post(url, request, {
      headers: {
        "X-CSRF-TOKEN": token
      },
    })
      .then( async (response) => {
          if ( response?.data?.status === 'success' ) {
            commit('FAST_LINE_PUSH_SAVED_FILTERS', response.data.data)
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.filters.filter_created') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },
  async FastLineAxiosDeleteFilter({ state, commit, dispatch }, { nameModule, nameFilter }) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const user_id = state.user.id;

    const url = `${engine_url}/kw/custom_modules/delete_filter`

    let request = {
      kw_module_name: `fast_line_${nameModule}`,
      name: nameFilter,
      user_id,
    }
    dispatch('updateAjaxDialog', [true, true]);
    await axios.delete(url, {
      headers: {
        "X-CSRF-TOKEN": token
      },
      data:request,
    })
      .then( async (response) => {
          if ( response?.data?.status === 'success' ) {
            commit('FAST_LINE_DELETE_SAVED_FILTERS', nameFilter)
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.filters.filter_deleted') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },
  FastLineActivateSavedFilter({ state, commit, dispatch } , data) {
    //clear data values and condition in Filters List (set null)
    for (let f in state.fast_line.selected_filters) {
      commit('FAST_LINE_SET_SELECTED_FILTER_VALUE', {key: f, value: null})
      commit('FAST_LINE_SET_SELECTED_FILTER_CONDITION', {key: f, condition: null})
    }

    if (data?.filters_json) {
      for(let item of data.filters_json) {
        let name = item[0]
        let value = item[1].value
        let condition = item[1].condition

        commit('FAST_LINE_SET_SELECTED_FILTER_VALUE', {key: name, value: value})
        commit('FAST_LINE_SET_SELECTED_FILTER_CONDITION', {key: name, condition: condition})
      }
    }

    //set columns from saved filter or set saved from model
    if (data?.columns_chooser) {
      commit('FAST_LINE_SET_COLUMNS_DATA', data.columns_chooser)
    } else {
      let columns;
      (state.fast_line.saved_columns_data?.length)
        ? columns = state.fast_line.saved_columns_data
        : columns = state.fast_line.default_columns_data

      commit('FAST_LINE_SET_COLUMNS_DATA', _.cloneDeep(columns) )
    }
  },
  FastLineSetEmptySelectedFilter({ commit }, {key, values}) {
    commit('FAST_LINE_SET_EMPTY_SELECTED_FILTER', {key, values})
  },
  FastLineSetSelectedFilter({ commit }, {key, values}) {
    commit('FAST_LINE_SET_SELECTED_FILTER', {key, values})
  },
  FastLineSetSelectedFilterValue({ commit }, {key, value}) {
    commit('FAST_LINE_SET_SELECTED_FILTER_VALUE', {key, value})
  },
  FastLineSetSelectedFilterCondition({ commit }, {key, condition}) {
    commit('FAST_LINE_SET_SELECTED_FILTER_CONDITION', {key, condition})
  },

  FastLineDeleteSelectedFilter({ commit }, key) {
    commit('FAST_LINE_DELETE_SELECTED_FILTER', key)
  },
  FastLineDeleteSelectedFilterInArray({ commit }, {key, value}) {
    commit('FAST_LINE_DELETE_SELECTED_FILTER_IN_ARRAY', {key, value})
  },
  FastLineSetSelectedFiltersEmpty({ commit }) {
    commit('FAST_LINE_SELECTED_FILTERS_CLEAR_VALUES')
  },


  FastLineSetColumnsData({ commit }, data) {
    let arr = []
    if (data.length && data[0].hasOwnProperty('position')) {
      arr = data
    } else if(data.length) {
      for (let i in data) {
        let item = {
          ...data[i],
          active: true,
          position: Number(i),
          sort: null,
        }

        arr.push(item)
      }
    }

    commit('FAST_LINE_SET_COLUMNS_DATA', arr)
  },
  /**
   * @name FastLineSetDefaultColumnsData
   * @description save Default Columns in state
   * @param commit
   * @param data
   */
  FastLineSetDefaultColumnsData({ commit }, data) {
    let arr = []
    for (let i in data) {
      let item = {
        ...data[i],
        active: ["question", "answer", "actions"].includes(data[i].value),
        position: Number(i),
        sort: null,
      }

      arr.push(item)
    }
    commit('FAST_LINE_SET_DEFAULT_COLUMNS_DATA', arr)
  },
  FastLineSetColumnsDataFromActivated({ getters, commit }, mode) {
    if (mode) {
      commit('FAST_LINE_SET_COLUMNS_DATA', [])
      commit('FAST_LINE_SET_COLUMNS_DATA_ACTIVATED', [])
    } else {
      commit('FAST_LINE_SET_COLUMNS_DATA', getters.fast_line_columns_data_activated)
    }
  },

  FastLineSetToggleActiveFilter({ dispatch, commit }, value) {
    commit('FAST_LINE_SET_TOGGLE_ACTIVE_FILTER', value)
    dispatch('FastLineActivateSavedFilter', value)
  },

  //Columns ACTIONS
  FastLineToggleColumnActive({ commit }, {name, value}) {
    commit('FAST_LINE_TOGGLE_COLUMN_ACTIVE', {name, value})
  },
  FastLineChangeOrderColumn({ state, commit }, data) {
    commit('FAST_LINE_CHANGE_ORDER_COLUMN', data)
  },
  async FastLineAxiosSaveColumnsData({ state, dispatch, commit, }, {name, reset}) {
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    // const bot_id = router.currentRoute?.params?.id || state.bot_settings.active_id;
    const columns_chooser =
      reset ? {} : state.fast_line.columns_data_activated

    const url = `${engine_url}/kw/custom_modules/create_update_filter`

    let request = {
      // bot_id,
      user_id: state.user.id,
      name,
      columns_chooser,
    }
    dispatch('updateAjaxDialog', [true, true]);

    await axios.post(url, request, {
      headers: {
        "X-CSRF-TOKEN": token
      },
    })
      .then( async (response) => {
          dispatch('updateAjaxDialog', [false, false]);
          if ( response?.data?.status === 'success' ) {
            dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.columns_chooser.columns_saved_success') ]);
          }
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_save") ]);
        }
      );
  },
  FastLineResetColumnsDataToDefault({ state, dispatch }, moduleName) {
    dispatch('FastLineAxiosSaveColumnsData', {name: null, reset: true})
    Vue.set(state.fast_line, 'columns_data', state.fast_line.default_columns_data)
  },

  FastLineResetColumnsDataForFilterToSaved({ state, commit }) {
    let columns;

    (state.fast_line.saved_columns_data?.length)
      ? columns = _.cloneDeep(state.fast_line.saved_columns_data)
      : columns = _.cloneDeep(state.fast_line.default_columns_data)

    commit('FAST_LINE_SET_COLUMNS_DATA', columns)
  },
  //end Columns ACTIONS

  async FastLineAxiosSetOpenAIToken({state, dispatch, commit}, data) {
		const { value, reset = null } = {...data}
    const token = Vue.$cookies.get('token_e')
    const engine_url = state.engine_settings.active_url;
    const bot_id = router.currentRoute?.params?.id || state.bot_settings.active_id;
    const url = `${engine_url}/kw/fast_line/set_openai_token`;
    const params = {
      openai_token: value,
      bot_id,
    }

    await axios({
      method: 'POST',
      url: url,
      headers: {
        'X-CSRF-TOKEN': token,
      },
      data: params,
    })
      .then( (response) => {
				let resultText = i18n.t('modules.fast_line.ai.openai_token_is_set')
					if(reset) resultText = i18n.t('modules.fast_line.ai.openai_token_is_removed')

          dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), resultText ]);
          commit('FAST_LINE_SET_OPENAI_TOKEN', response.data?.openai_token || false)
        },
        (err) => {
          dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
        }
      );
  },
};

const getters = {
  fast_line_tabs: (state) => state.fast_line.tabs,
  fast_line_available_tab: (state) => {
    let currentStep = 1
    if (!state.fast_line.tabs[3].disabled) {
      currentStep = 4
    } else if (!state.fast_line.tabs[2].disabled) {
      currentStep = 3
    } else if(!state.fast_line.tabs[1].disabled) {
      currentStep = 2
    } else if (!state.fast_line.tabs[0].disabled) {
      currentStep = 1
    }

    return currentStep
  },

  fast_line_filters: (state) => state.fast_line.filters,
  fast_line_linked_tables_values: (state) => state.fast_line.linkedTablesValues,
  fast_line_selected_filters: (state) => state.fast_line.selected_filters,
  fast_line_saved_filters: (state) => state.fast_line.saved_filters.filter((f) => !!f?.name),

  fast_line_toggle_active_filter: (state) => state.fast_line.toggle_active_filter,

  fast_line_columns_data: (state) => state.fast_line.columns_data,
  fast_line_columns_data_activated: (state) => state.fast_line.columns_data_activated,
  fast_line_saved_columns_data: (state) => state.fast_line.saved_columns_data,
  fast_line_default_columns_data: (state) => state.fast_line.default_columns_data,

  fast_line_get_openai_token: (state) => state.fast_line.openai_token,
  fast_line_get_widget_url: (state) => state.fast_line.widget_url,
  fast_line_get_in_generation: (state) => state.fast_line.in_generation,
  fast_line_get_in_education: (state) => state.fast_line.in_education,
};

export default {
  state,
  mutations,
  actions,
  getters,
};

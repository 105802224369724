<template>
    <v-snackbar
      :timeout="-1"
      :value="open"
      absolute
      top
      :color="'warning'"
      text
      class="draggable-snackbar"
      ref="draggableSnackbar"
    >
      <div class="text-caption">
        {{$t('is_convenient.alert_text_record')}}
      </div>

      <template  v-slot:action="{ attrs }">
        <v-btn
          color="warning"
          outlined
          small
          v-bind="attrs"
          @click="$emit('update:open', false)"
          class="text-none"
        >
          {{$t('is_convenient.btn_stop_record')}}
        </v-btn>
      </template>
    </v-snackbar>

</template>

<script>

export default {
  name: 'SnackBarDraggable',

  props: {
    open: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data: () => ({
    isDragging: false,
    dragStartX: 0,
    dragStartY: 0,
    offsetX: 0,
    offsetY: 0,
  }),

  mounted() {
    this.showSnackbar();
  },

  methods: {
    showSnackbar() {
      this.$nextTick(() => {
        this.makeDraggable(this.$refs.draggableSnackbar);
      });
    },
    makeDraggable(element) {
      const snackbar = element.$el;

      snackbar.addEventListener('mousedown', this.onMouseDown);
      document.addEventListener('mouseup', this.onMouseUp);
      document.addEventListener('mousemove', this.onMouseMove);
    },
    onMouseDown(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      const snackbar = this.$refs.draggableSnackbar.$el;
      this.offsetX = snackbar.offsetLeft;
      this.offsetY = snackbar.offsetTop;
    },
    onMouseUp() {
      this.isDragging = false;
    },
    onMouseMove(event) {
      if (!this.isDragging) return;
      const snackbar = this.$refs.draggableSnackbar.$el;
      const dx = event.clientX - this.dragStartX;
      const dy = event.clientY - this.dragStartY;
      snackbar.style.left = `${this.offsetX + dx}px`;
      snackbar.style.top = `${this.offsetY + dy}px`;
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable-snackbar {
  z-index: 1000;
  position: fixed;
  cursor: move;
}
</style>
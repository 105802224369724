<template>
  <div style="padding: 12px;">
    <Stepper
      v-model="curStep"
    >
      <template v-slot:header>
        <div class="text-h6">{{ $t('modules.customdata.onboarding_screen.title') }}</div>
        <div class="text-body-1 mb-3">{{ $t('modules.customdata.onboarding_screen.description') }}</div>
      </template>

      <template v-slot:steps>
        <v-stepper-step
          color="primary"
          editable
          step="1"
        >
          {{ $t('modules.customdata.onboarding_screen.step_1.title') }}
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-alert
            border="right"
            color="indigo"
            dark
          >
            {{$t('modules.customdata.onboarding_screen.step_1.descr_3')}}
          </v-alert>

          <p v-html="$t('modules.customdata.onboarding_screen.step_1.descr_1')" />
          <p v-html="$t('modules.customdata.onboarding_screen.step_1.descr_2')" />
          <v-alert
            dense
            border="left"
            text
            type="warning"
            v-html="$t('modules.customdata.onboarding_screen.step_1.warning')"
          />
          <v-btn
            color="primary"
            link
            dense
            small
            class="mb-3"
            text
            target="_blank" rel="noopener"
            elevation="0"
            :href="$t('modules.customdata.notion.custom_data_notion_link')"
          >{{$t('modules.customdata.onboarding_screen.step_1.btn_text')}}</v-btn>
        </v-stepper-content>

        <v-stepper-step
          color="primary"
          editable
          step="2"
        >
          {{ $t('modules.customdata.onboarding_screen.step_2.title') }}
        </v-stepper-step>

        <v-stepper-content step="2">
          <p v-html="$t('modules.customdata.onboarding_screen.step_2.descr_1')" />
          <p v-html="$t('modules.customdata.onboarding_screen.step_2.descr_2')" />
          <v-btn
            color="primary"
            link
            dense
            small
            class="mb-3"
            text
            target="_blank" rel="noopener"
            elevation="0"
            :href="$t('modules.customdata.notion.customdata_import_notion_link')"
          >{{$t('modules.customdata.onboarding_screen.step_2.btn_text')}}</v-btn>
        </v-stepper-content>

        <v-stepper-step
          color="primary"
          editable
          step="3"
        >
          {{ $t('modules.customdata.onboarding_screen.step_3.title') }}
        </v-stepper-step>

        <v-stepper-content step="3">
          <p v-html="$t('modules.customdata.onboarding_screen.step_3.descr_1')" />
          <p v-html="$t('modules.customdata.onboarding_screen.step_3.descr_2')" />
          <v-btn
            color="primary"
            link
            dense
            small
            class="mb-3"
            text
            target="_blank" rel="noopener"
            elevation="0"
            :href="$t('modules.customdata.notion.customdata_scenario_notion_link')"
          >{{$t('modules.customdata.onboarding_screen.step_3.btn_text')}}</v-btn>
        </v-stepper-content>

        <v-stepper-step
          color="primary"
          editable
          step="4"
        >
          {{ $t('modules.customdata.onboarding_screen.step_4.title') }}
        </v-stepper-step>

        <v-stepper-content step="4">
          <p v-html="$t('modules.customdata.onboarding_screen.step_4.descr_1')" />
          <v-btn
            color="primary"
            link
            dense
            small
            class="mb-3"
            text
            target="_blank" rel="noopener"
            elevation="0"
            :href="$t('modules.customdata.notion.customdata_broadcast_users_notion_link')"
          >{{$t('modules.customdata.onboarding_screen.step_4.btn_text')}}</v-btn>
        </v-stepper-content>
      </template>

      <template v-slot:right>
        <v-alert
          v-if="curStep === '1' && !custom_data_tables.length"
          dense
          border="left"
          type="info"
        >
          {{$t('modules.customdata.onboarding_screen.step_1.info')}}
        </v-alert>

        <CreateAiMagicData v-if="curStep === '1'" />
        <CreateFastData 
          v-if="curStep === '1'" 
          :route_from="routeFrom"
        />
        <div v-if="curStep === '1'" class="mb-4"></div>

        <IframeVideo
          :src="$t('modules.customdata.notion.videos.customdata_intro')"
          :title="'Kwizbot intro'"
          :aspect_ratio="'1348/1080'"
        />
      </template>
    </Stepper>
  </div>

</template>

<script>
import Stepper from "@/components/Modules/OnBoardingScreens/Stepper.vue"
import CreateFastData from "@/modules/CustomData/components/CreateFastData.vue";
import IframeVideo from "@/components/Modules/Extra/Iframes/IframeVideo.vue";
import CreateAiMagicData from "@/modules/CustomData/components/CreateAiMagicData.vue";
import {mapGetters} from "vuex";

export default {
  name: "BroadcastOnBoardingScreen",

  components: {
    Stepper,
    CreateFastData,
    IframeVideo,
    CreateAiMagicData,
  },

  data: () => ({
    curStep: '1',
    routeFrom: '',
  }),

  computed: {
    ...mapGetters([
      'custom_data_tables',
    ]),
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = from?.path;
    });
  },
}
</script>
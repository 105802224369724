<template>
  <svg :width="width" :height="height" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_65_2)">
      <path d="M2.37627 9.63973C2.37627 10.4813 2.56065 11.1247 2.80164 11.5149C2.93007 11.737 3.115 11.9212 3.33766 12.0487C3.56031 12.1762 3.81276 12.2426 4.06934 12.2409C4.68904 12.2409 5.25595 12.0879 6.34845 10.5762C7.22368 9.36507 8.25497 7.66894 8.94965 6.59786L10.1248 4.79232C10.9411 3.53839 11.8844 2.14446 12.9693 1.19655C13.8529 0.428432 14.8069 0 15.7671 0C17.3791 0 18.9145 0.934136 20.0897 2.68612C21.3757 4.60488 22.0023 7.0217 22.0023 9.51579C22.0023 10.9985 21.71 12.0879 21.2128 12.9486C20.7331 13.781 19.7936 14.6126 18.2183 14.6126V12.2409C19.5671 12.2409 19.9014 11.0015 19.9014 9.58311C19.9014 7.56183 19.4302 5.31868 18.392 3.71589C17.6552 2.57901 16.7004 1.88434 15.65 1.88434C14.5139 1.88434 13.5997 2.7412 12.5722 4.26902C12.0259 5.08075 11.4652 6.06997 10.8355 7.18619L10.1424 8.41028C8.74997 10.8791 8.39727 11.4414 7.70107 12.3695C6.4831 14.0006 5.44109 14.6126 4.06934 14.6126C2.44206 14.6126 1.41306 13.908 0.779594 12.8461C0.255529 11.9808 0 10.8455 0 9.55175L2.37627 9.63973Z" fill="#0081FB"/>
      <path d="M1.87366 2.85367C2.9631 1.17436 4.53529 0 6.33853 0C7.38284 0 8.42102 0.306023 9.50511 1.19426C10.6909 2.16205 11.9533 3.75567 13.5316 6.38211L14.097 7.32466C15.4618 9.59841 16.2391 10.7674 16.6928 11.3198C17.2773 12.0282 17.6874 12.2379 18.2184 12.2379C19.5671 12.2379 19.9015 10.9985 19.9015 9.58005L21.9977 9.51426C21.9977 10.9969 21.7055 12.0864 21.2082 12.9471C20.7331 13.781 19.7936 14.6126 18.2184 14.6126C17.2391 14.6126 16.3715 14.3999 15.4121 13.4949C14.6746 12.8002 13.8124 11.5661 13.1491 10.4568L11.176 7.16094C10.186 5.50689 9.27789 4.27361 8.75229 3.71818C8.18615 3.11455 7.46011 2.38928 6.30028 2.38928C5.36156 2.38928 4.56437 3.04799 3.89723 4.05557L1.87366 2.85367Z" fill="url(#paint0_linear_65_2)"/>
      <path d="M6.30025 2.38928C5.36152 2.38928 4.56433 3.04799 3.8972 4.05557C2.95389 5.47934 2.37627 7.60008 2.37627 9.63973C2.37627 10.4813 2.56065 11.1247 2.80164 11.5149L0.775768 12.8461C0.255529 11.9808 0 10.8455 0 9.55175C0 7.19919 0.645709 4.74718 1.87363 2.85367C2.96307 1.17436 4.53526 0 6.3385 0L6.30025 2.38928Z" fill="url(#paint1_linear_65_2)"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_65_2" x1="4.7694" y1="6.9276" x2="19.9175" y2="7.69266" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0064E1"/>
        <stop offset="0.4" stop-color="#0064E1"/>
        <stop offset="0.83" stop-color="#0073EE"/>
        <stop offset="1" stop-color="#0082FB"/>
      </linearGradient>
      <linearGradient id="paint1_linear_65_2" x1="3.16887" y1="10.6343" x2="3.16887" y2="5.04938" gradientUnits="userSpaceOnUse">
        <stop stop-color="#0082FB"/>
        <stop offset="1" stop-color="#0064E0"/>
      </linearGradient>
      <clipPath id="clip0_65_2">
        <rect width="22" height="14.6126" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconFbMeta'
            },
            width: {
                type: [Number, String],
                default: 22
            },
            height: {
                type: [Number, String],
                default: 15
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>